export default function Accordion(theme){
    const {grey, primary} = theme.palette;
    const isDark = theme.palette.mode==='dark';
    return {
        defaultProps: {
            elevation: 0,
            disableGutters: !0
        },
        styleOverrides: {
            root: {
                overflow: "hidden",
                marginBottom: "1rem",
                border: `1px solid ${grey[isDark ? 700 : 100]}`,
                transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                ":before": {
                    display: "none"
                },
                ":last-of-type": {
                    marginBottom: 0
                }
            },
            rounded: {
                borderRadius: "1rem !important"
            }
        },
        variants: [{
            props: {
                variant: "outlined"
            },
            style: {
                borderLeftWidth: 3,
                ".MuiAccordionDetails-root": {
                    paddingTop: "1rem"
                },
                "&.Mui-expanded": {
                    borderColor: primary.main,
                    ".MuiAccordionSummary-root": {
                        color: primary.main,
                        backgroundColor: grey[isDark ? 900 : 50]
                    },
                    ".MuiAccordionSummary-expandIconWrapper": {
                        color: primary.main
                    }
                }
            }
        }]
    }
}