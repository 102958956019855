import {SvgIcon} from "@mui/material";

export default function Rating(theme){
    return {
        styleOverrides: {
            root: {
                color: theme.palette.warning.main
            },
            iconEmpty: {
                color: theme.palette.grey[300]
            }
        },
        defaultProps: {
            icon: <Star fontSize="inherit"/> ,
            emptyIcon: <StarOutlined/>
        }
    }
}

const Star = (props)=><SvgIcon {...props}>
    <path
        d="M21.12 9.88a.74.74 0 0 0-.6-.51l-5.42-.79-2.43-4.91a.78.78 0 0 0-1.34 0L8.9 8.58l-5.42.79a.74.74 0 0 0-.6.51.75.75 0 0 0 .18.77L7 14.47l-.93 5.4a.76.76 0 0 0 .3.74.751.751 0 0 0 .79.05L12 18.11l4.85 2.55a.73.73 0 0 0 .35.09.791.791 0 0 0 .44-.14.76.76 0 0 0 .3-.74l-.94-5.4 3.93-3.82a.75.75 0 0 0 .19-.77Z"
    />
</SvgIcon>

const StarOutlined = (props)=><SvgIcon {...props}>
    <path
        d="M17.2 20.75a.73.73 0 0 1-.35-.09L12 18.11l-4.85 2.55a.75.75 0 0 1-.79-.05.76.76 0 0 1-.3-.74l.94-5.4-3.94-3.82a.75.75 0 0 1-.18-.77.74.74 0 0 1 .6-.51l5.42-.79 2.43-4.91a.78.78 0 0 1 1.34 0l2.43 4.91 5.42.79a.74.74 0 0 1 .6.51.75.75 0 0 1-.18.77L17 14.47l.93 5.4a.76.76 0 0 1-.3.74.79.79 0 0 1-.43.14ZM12 16.52a.85.85 0 0 1 .35.08l3.85 2-.73-4.29a.781.781 0 0 1 .21-.67l3.12-3-4.31-.64a.76.76 0 0 1-.56-.41L12 5.69 10.07 9.6a.76.76 0 0 1-.56.41l-4.31.63 3.12 3a.779.779 0 0 1 .21.67l-.73 4.32 3.85-2a.848.848 0 0 1 .35-.11Z"
    />
</SvgIcon>