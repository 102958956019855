import CustomEditor from "../CustomEditor";
import {useSlate} from "slate-react";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import {Grid,Tooltip} from "@mui/material";
import ToolbarButton from "./ToolbarButton";
import {linkTooltip} from "./Tooltips";

export default function LinkButton({}){
    const editor = useSlate();
    return <Grid item>
        <Tooltip title={linkTooltip} placement={'top'} arrow>
            <ToolbarButton
                variant={CustomEditor.isLinkActive(editor)?'contained':'text'}
                color={CustomEditor.isLinkActive(editor)?'primary':'secondary'}
                onMouseDown={(e)=>{
                    e.preventDefault();
                    CustomEditor.toggleLink(editor);
                }}
            >
                <InsertLinkRoundedIcon/>
            </ToolbarButton>
        </Tooltip>
    </Grid>
}