import React from "react";
import {Box, Button, Card, Grid, Typography} from "@mui/material";
import Body from "./Body";

export default function EditUser(){
    return <Box>
        <Card>
            <Box p={2}>
                <Typography variant={"h4"} pb={1}>
                    Edit User
                </Typography>
                <Body/>
                <Grid container justifyContent={'flex-end'}>
                    <Button>
                        Save
                    </Button>
                </Grid>
            </Box>
        </Card>
    </Box>
}