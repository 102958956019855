import React from "react";
import {alpha, Chip} from "@mui/material";
import {getStatusBg} from "../KanbanBoard/BoardTicketCard";

export default React.forwardRef(function StatusChip({status,...rest},ref){
    return <Chip
        {...rest}
        size={'small'}
        ref={ref}
        sx={(theme)=>({
            fontSize:10,
            height:16,
            background:alpha(getStatusBg(theme,status),.2),
            color:'text.primary'
        })} 
        label={status}
    />
})