import React from "react";
import {Box} from "@mui/material";

export default function ToolbarLogo(){
    return <Box sx={{
        height:28,
        width:28,
        '&>img': {
            width:'100%',
            height:'100%'
        }
    }}>
        <img src={'/logo192.png'} alt="logo" />
    </Box>
}