import './App.css';
import {Provider} from "react-redux";
import ToggleThemeModeProvider from "./theme/ToggleThemeModeProvider";
import {SnackbarProvider} from "notistack";
import {
  RouterProvider
} from "react-router-dom";
import router from "./router";
import store from "./store";
import { LocalizationProvider, } from '@mui/x-date-pickers';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

function App() {
  return (<Provider store={store}>
    <ToggleThemeModeProvider>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </SnackbarProvider>
    </ToggleThemeModeProvider>
  </Provider>);
}

export default App;
