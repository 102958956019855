export default function ListItemText(){
    return {
        styleOverrides: {
            root: {
                margin: 0
            },
            multiline: {
                margin: 0
            }
        }
    }
}