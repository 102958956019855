import {configureStore} from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import userReducer from "./userSlice";
import ticketsReducer from "./ticketsSlice";
import {combineReducers} from "@reduxjs/toolkit";
import optionsReducer from "./optionsSlice";

const rootReducer = combineReducers({
    themeReducer,
    userReducer,
    ticketsReducer,
    optionsReducer,
});

const USER_LOGOUT = 'USER_LOGOUT';

export const clearStore = {type: USER_LOGOUT};

// root reducer which can clear store to initial state   
const reducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        return rootReducer(undefined, action)
    }
    return rootReducer(state, action)
};

const store = configureStore({
    reducer,
});

export default store;