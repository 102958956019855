import {createTheme} from "@mui/material/styles";
import {shadows} from "./shadows";
import {primary, secondary, success, warning, error, info, grey} from "./colors";
import components from "./components";

const theme = createTheme({
    palette:{
        mode: 'dark',
        primary,
        secondary,
        success,
        warning,
        error,
        info,
        grey,
        background:{
            paper:grey[800]
        }
    },
    typography: { fontFamily: "'Inter', sans-serif" },
    shadows:shadows('dark'),
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});


const darkTheme = createTheme({
    ...theme,
    components:components(theme)
});

export default darkTheme;