export default function createOnDragEnd(
    updateTicket,
    setBoardValue,
    setOpenEdit,
    setEditedTicket,
    setForceStatus,
    moveTicketOnBoard,
    boardValue
){
    return (result,provided)=>{
        if(
            !result.destination
            ||result.destination.droppableId===result.source.droppableId
        ) {
            return;
        }

        const [destPriority,destStatus] = result.destination.droppableId.split('-');
        const [sourcePriority,sourceStatus] = result.source.droppableId.split('-');
        if(destPriority!==sourcePriority) {
            return;
        }

        if(destStatus==='declined') {
            let declined = boardValue.tickets[sourcePriority][sourceStatus]
                .find(el=>el.id.toString()===result.draggableId);
            setOpenEdit(true);
            setEditedTicket({
                ...declined,
            });
            setForceStatus(destStatus)
            return;
        }
        // send on back change and synchronize result
        updateTicket(result.draggableId,{status:destStatus})
            .then(response=>{
                if(response.data.status!==destStatus) {
                    setBoardValue(prevState => {
                        return moveTicketOnBoard(
                            prevState,
                            response.data,
                            sourcePriority,
                            sourceStatus,
                            sourcePriority,
                            response.data.status,
                            response.data.id.toString()
                        )
                    })
                }
            })
            .catch(e=> {
                console.log(e)
            })
        // change board state without waiting of response
        setBoardValue(prevState => {

            let item = prevState.tickets[sourcePriority][sourceStatus]
                .find(el=>el.id.toString()===result.draggableId);

            item = {
                ...item,
                status:destStatus,
            };
            return moveTicketOnBoard(
                prevState,
                item,
                sourcePriority,
                sourceStatus,
                sourcePriority,
                destStatus,
                result.draggableId
            )
        })

    }
}