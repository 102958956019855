import {KeyboardArrowLeft, KeyboardArrowRight} from "./DatePicker";

export default function TimePicker(){
    return{
        defaultProps: {
            slots: {
                leftArrowIcon: (props)=><KeyboardArrowLeft {...props} fontSize="small" />,
                rightArrowIcon:(props)=> <KeyboardArrowRight  {...props}  fontSize="small" />,
            },
            slotProps: {
                mobilePaper: {
                    sx: {
                        padding: 2,
                        boxShadow: 4,
                        borderRadius: 2,
                        ".MuiPickersArrowSwitcher-spacer": {
                            width: 10
                        },
                        ".MuiClock-pmButton .MuiTypography-caption": {
                            fontWeight: 600
                        },
                        ".MuiClock-amButton .MuiTypography-caption": {
                            fontWeight: 600
                        }
                    }
                }
            }
        }
    }
}