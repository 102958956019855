import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import React, {useMemo} from "react";
import {blue} from "@mui/material/colors";

export const PRIORITIES = ['highest','high', 'medium', 'low'];

export const STATUSES = ['todo', 'in_process', 'in_review', 'done', 'declined'];

export const TYPES = ['admin_panel', 'broker_integration', 'affiliate_panel', 'finance', 'binary', 'crm', 'api_integration', 'other'];

export const DECLINE_REASON = ['not_relevant','created_wrongly','already_exists','due_date'];

export function getLabel(value){
    let str = String(value).replaceAll('_',' ');
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function PriorityIcon({priority}){
    const color = useMemo(()=>{
        switch (priority){
            case 'highest':
                return 'error.main';
                break;
            case 'high':
                return 'warning.main';
                break;
            case 'medium':
                return 'success.main';
                break;
            case 'low':
                return blue[600];
                break;
            default:
                return 'inherit';
                break;
        }
    },[priority])
    return <CircleRoundedIcon 
        fontSize={"inherit"}
        sx={{
            mr:1, 
            color
        }} 
    />
}