import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField, 
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {blue} from "@mui/material/colors";
import {DatePicker} from "@mui/x-date-pickers";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

export default function CreateDialog({open,onClose}){
    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>
            Create new ticket
        </DialogTitle>
        <DialogContent sx={{minWidth:400}}>
           <Grid container py={2} spacing={2}>
               <Grid item xs={12}>
                   <TextField label={"Name"} fullWidth  />
               </Grid>
               <Grid item xs={12}>
                   <FormControl fullWidth>
                    <DatePicker label={"Due date"} />
                   </FormControl>
               </Grid>
               <Grid item xs={12}>
                   <TextField label={"Assignee"} fullWidth  />
               </Grid>
               <Grid item xs={12}>
                   <FormControl size={"small"} fullWidth>
                       <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                       <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           label="Priority"
                           defaultValue={""}
                       >
                           <MenuItem value={'highest'}>
                               <CircleRoundedIcon fontSize={"inherit"} color={"error"} sx={{mr:1}} /> Highest 
                           </MenuItem>
                           <MenuItem value={'high'}>
                               <CircleRoundedIcon fontSize={"inherit"} color={"warning"} sx={{mr:1}} /> High 
                           </MenuItem>
                           <MenuItem value={'medium'}>
                               <CircleRoundedIcon fontSize={"inherit"} color={"success"} sx={{mr:1}} /> Medium
                           </MenuItem>
                           <MenuItem value={'low'}>
                               <CircleRoundedIcon fontSize={"inherit"} sx={{color:blue[600],mr:1}} /> Low 
                           </MenuItem>
                       </Select>
                   </FormControl>
               </Grid>
               <Grid item xs={12}>
                   <TextField multiline minRows={3} label={"Description"} fullWidth />
               </Grid>
           </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={onClose}>
                Close
            </Button>
            <Button color={"success"} onClick={onClose}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
}