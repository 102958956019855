export default function Switch(theme){
    return {
        styleOverrides: {
            track: {
                borderRadius: 16,
                backgroundColor: theme.palette.grey[500]
            },
            switchBase: ({ownerState: {size: t}})=>({
                padding: t === "small" ? "6px !important" : 11
            }),
            root: ({ownerState: {size: t}})=>({
                padding: t === "small" ? 3 : 8
            }),
            thumb: ({ownerState: {size: t}})=>({
                width: 16,
                height: 16,
                ...t === "small" && {
                    width: "12px !important",
                    height: "12px !important"
                }
            })
        }
    }
}