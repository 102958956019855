import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    Drawer,
} from "@mui/material";
import AppMenu from "./Asside/AppMenu";
import AppDrawerHeader from "./Asside/AppDrawerHeader";
import AppToolbar from "./Main/AppToolbar";

const initialOpenLeftMenu = localStorage.getItem('openLeftMenu')!=='false';

export default function AppWrapper({children}){
    const [open,setOpen] = useState(initialOpenLeftMenu);
    useEffect(() => {
        localStorage.setItem('openLeftMenu',open);
    }, [open]);
    useEffect(() => {
        Notification.requestPermission()
    }, []);
    return <>
        <Box sx={(theme)=>({
            width:open ?280:86,
            height: 100,
            position:'fixed',
            border:0,
            transition:theme.transitions.create('width'),
            zIndex:1,
        })}>
            <Drawer
                variant="permanent" 
                sx={(theme)=>({
                    background:'transparent',
                    '& .MuiPaper-root': {
                        width:open ?280:86,
                        backgroundColor: theme.palette.mode==='dark'?theme.palette.grey[900]:"#fdfdff",
                        border:'none',
                        transition:theme.transitions.create('width'),
                        overflowX:'hidden'
                    }
                })} 
                open={open}
            >
                <AppDrawerHeader
                    open={open}
                    setOpen={setOpen}
                />
                <AppMenu
                    open={open}
                />
            </Drawer>
        </Box>
        <Box sx={(theme)=>({
            ml:open ?'280px':'86px',
            transition:theme.transitions.create('margin-left'),
        })}>
            <Container>
                <AppToolbar />
                {children}
            </Container>
        </Box>
    </>
}

