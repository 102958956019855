import React, {useMemo} from "react";
import {matchPath, NavLink, useLocation} from "react-router-dom";
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

export default function MenuBarItem(
    {
        name,
        Icon,
        link,
        open
    }
){
    const {pathname} = useLocation();
    const active = useMemo(
        ()=>matchPath(pathname,link)
        ,[pathname]);
    return <ListItem to={link} component={NavLink}>
        <ListItemButton sx={{
            borderRadius:2,
            color:active?"primary.main":'text.primary',
            '&:hover':{
                color:active?"primary.light":"primary.main"
            }
        }}>
            <ListItemIcon sx={{color:'inherit'}}>
                <Icon fontSize={'small'} color={'inherit'} />
            </ListItemIcon>
            <ListItemText
                sx={{opacity:open ?1:0}}
                primaryTypographyProps={{sx: {fontSize:14}}}
            >
                {name}
            </ListItemText>
        </ListItemButton>
    </ListItem>
}