export default function ListItemIcon(theme){
    return {
        styleOverrides: {
            root: {
                marginRight: 12,
                minWidth: "auto !important",
                color: theme.palette.grey[600]
            }
        }
    }
}