export default function InputLabel(theme){
    return {
        styleOverrides: {
            sizeSmall: {
                fontSize: 14,
                lineHeight: 1.7
            },
            standard: {
                fontWeight: 500
            },
            filled: {
                fontWeight: 500,
                "&.Mui-focused": {
                    fontWeight: 600
                },
                "&.Mui-disabled": {
                    color: theme.palette.grey[300]
                }
            }
        }
    }
}