import React from "react";
import {Card, Box, Typography, Button, TextField, IconButton} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {NavLink} from "react-router-dom";
import paths from "../../router/paths";

const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'last_name', headerName: 'Last name', width: 150 },
    { field: 'user_login',headerName: 'User Login',width:150},
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'role', headerName: 'Role', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'permission_list', headerName: 'Permission list', width: 150 },
    { field: 'action', headerName: 'Action', width: 100,headerAlign:'center',
        renderCell:()=>(<Box display={'flex'} onClick={(e)=>{
            e.stopPropagation();
        }} width={'100%'} justifyContent={'center'}>
            <IconButton size={'small'} to={paths.users.edit.path.replace(':id','12')} component={NavLink}>
            <EditRoundedIcon fontSize={'small'} />
        </IconButton>
        </Box>)
    },
];

const rows = [
    {
        id:1,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:2,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:3,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:4,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:5,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:6,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:7,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:8,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
    {
        id:9,
        name:'Jan',
        last_name:'Yang',
        user_login:'login',
        email:'mail@mail.com',
        role:'admin',
        status:'active',
        permission_list:['update','create','decline']
    },
]

export default function Users(){
    return <Box>
        <Card>
            <Box py={2}>
                <Box px={2}>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}>
                        <Typography fontSize={18} fontWeight={600}>
                            Users
                        </Typography>
                        <Button
                            component={NavLink}
                            to={paths.users.create.path}
                            endIcon={<AddRoundedIcon/>}
                        >
                            Add new user
                        </Button>
                    </Box>
                    <Box display={'flex'} gap={2} pt={2} flexWrap={'wrap'}>
                        <TextField 
                            label={'ID'}
                        />
                        <TextField
                            label={'Name'}
                        />
                        <TextField
                            label={'Last name'}
                        />
                        <TextField
                            label={'User login'}
                        />
                        <TextField
                            label={'Email'}
                        />
                        <TextField
                            label={'Role'}
                        />
                        <TextField
                            label={'Status'}
                        />
                        <TextField
                            label={'Permission list'}
                        />
                        <Button endIcon={<FilterAltRoundedIcon/>}>
                            Filter
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    height:`calc(100vh - 300px)`,
                    minHeight:400,
                    pt:2,
                    width:'100%'
                }}>
                <DataGrid 
                    columns={columns}
                    rows={rows}
                    checkboxSelection
                    sx={{
                       '& .MuiDataGrid-main>*:first-of-type':{
                            borderRadius:0
                        }
                    }}
                />
                </Box>
            </Box>
        </Card>
    </Box>
}