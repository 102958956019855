export default function Tab(theme){
    return {
        defaultProps: {
            disableRipple: !0
        },
        styleOverrides: {
            root: {
                padding: 0,
                minHeight: 40,
                fontWeight: 400,
                minWidth: "auto",
                textTransform: "none",
                "&.Mui-selected": {
                    fontWeight: 600
                }
            },
            labelIcon: ({ownerState: {iconPosition: t}})=>({
                ...(t === "top" || t === "bottom") && {
                    marginBottom: 8
                }
            }),
            textColorSecondary: {
                "&.Mui-selected": {
                    color: theme.palette.text.primary
                }
            },
            iconWrapper: {
                marginBottom: 0,
                fontSize: "1.2rem"
            }
        }
    }
}