import {Editor, Element, Transforms,Range} from "slate";
import isUrl from 'is-url';

const LIST_TYPES = ['ul_list', 'ol_list'];
const CustomEditor = {
    isMarkActive(editor,format){
        const marks = Editor.marks(editor);
        return marks ? !!marks[format]:  false;
    },
    isLinkActive(editor)  {
        const [link] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
        })
        return !!link
    },
    isBlockActive(editor,format){
        const { selection } = editor
        if (!selection) return false

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    Element.isElement(n) &&
                    n['type'] === format,
            })
        )

        return !!match
    },
    toggleMark(editor,format){
        const isActive = CustomEditor.isMarkActive(editor,format);
        if(isActive){
            Editor.removeMark(editor,format);
        }else {
            Editor.addMark(editor,format,true);
        }
    },
    toggleBlock(editor,format){
        const isActive = CustomEditor.isBlockActive(
            editor,
            format,
            'type'
        )
        const isList = LIST_TYPES.includes(format);
        const isBlock = format;
        
        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                Element.isElement(n) &&
                (LIST_TYPES.includes(n.type)||n.type==='code_block'||n.type==='block_quote'),
            split: true,
        });
        
        let newProperties;
       
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list_item' :isBlock?'code_line': format,
        }
        Transforms.setNodes(editor, newProperties);
        if (!isActive && (isList||isBlock)) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    },
    unwrapLink(editor){
        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
        })
    },
    insertLink(editor, url){
        if (!editor.selection) {
            return;
        }
        
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)
        const link = {
            type: 'link',
            link:url,
            children: isCollapsed ? [{ text: url }] : [],
        }

        if (isCollapsed) {
            Transforms.insertNodes(editor, link)
        } else {
            Transforms.wrapNodes(editor, link, { split: true })
            Transforms.collapse(editor, { edge: 'end' })
        }

    },
    toggleLink(editor){
        if (CustomEditor.isLinkActive(editor)) {
            CustomEditor.unwrapLink(editor);
            return;
        }

        const url = window.prompt('Enter the URL of the link:')
        if (!url) return;
        CustomEditor.insertLink(editor, url)
    }
    
}

export const withInlines = editor => {
    const { insertData, insertText, isInline, } =
        editor

    editor.isInline = element =>
        ['link'].includes(element.type) || isInline(element)
    
    editor.insertText = text => {
        if (text && isUrl(text)) {
            CustomEditor.insertLink(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text && isUrl(text)) {
            CustomEditor.insertLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor
}

export default CustomEditor;