import {ObjectMap} from "../Utils";

const addPath = ([key,val],path)=>{
    if(key==='path'){
        return [key,path+val]
    }
    return  [key,ObjectMap(val,(entr)=>addPath(entr,path))]
};

const createPath = (path,subTree)=>({
    // function crate path object {
    // path,
    // subTree
    // }
    // path:string - sub path to page
    // if it part of subTree it will contain full path with all parent paths
    // subTree:object each field of this object is createPath() result
    path,
    ...(subTree&&ObjectMap(subTree,(entr)=>addPath(entr,path))),
});

const paths = {
    root:createPath('/'),
    tickets:createPath('/tickets',{
        chat:createPath('/:id')
    }),
    login:createPath('/login'),
    logout:createPath('/logout'),
    profile:createPath('/profile'),
    users:createPath('/users',{
        create:createPath('/create'),
        edit:createPath('/edit/:id'),
    }),
    permissions:createPath('/permissions')
};


export default paths;