import React from "react";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import paths from "../../../router/paths";
import MessageIcon from "@mui/icons-material/Message";
import {List} from "@mui/material";
import MenuBarItem from "./MenuBarItem";
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';

export default function AppMenu({open}){
    return <List>
        <MenuBarItem
            name={'Board'}
            Icon={DashboardRoundedIcon}
            link={paths.root.path}
            open={open}
        />
        <MenuBarItem
            name={"Tickets"}
            Icon={MessageIcon}
            link={paths.tickets.path}
            open={open}
        />
        <MenuBarItem
            name={"Users"}
            Icon={GroupRoundedIcon}
            link={paths.users.path}
            open={open}
        />
        <MenuBarItem
            name={"Permissions"}
            Icon={LockPersonRoundedIcon}
            link={paths.permissions.path}
            open={open}
        />
    </List>
}