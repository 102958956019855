export default function IconButton(theme){
    return {
        styleOverrides: {
            colorSecondary: {
                color: theme.palette.grey[400],
                ":hover": {
                    color: theme.palette.primary.main
                }
            }
        }
    }
}