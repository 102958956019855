export default function ButtonGroup(theme){
    const {primary, success, error, warning, secondary,mode} = theme.palette;
    const isDark = mode==='dark';
    return {
        styleOverrides: {
            root: {
                boxShadow: "none"
            },
            groupedContainedPrimary: {
                "&:not(:last-of-type)": {
                    borderColor: primary[600]
                }
            },
            groupedContained: ({ownerState: {color: s}})=>({
                ...s === "success" && {
                    "&:not(:last-of-type)": {
                        borderColor: success[600]
                    }
                },
                ...s === "error" && {
                    "&:not(:last-of-type)": {
                        borderColor: error[400]
                    }
                },
                ...s === "warning" && {
                    "&:not(:last-of-type)": {
                        borderColor: warning[400]
                    }
                }
            }),
            groupedContainedSecondary: {
                backgroundColor: secondary[isDark ? 700 : 200],
                "&:not(:last-of-type)": {
                    borderColor: secondary[300]
                },
                ":hover": {
                    backgroundColor: secondary[isDark ? 800 : 300]
                }
            },
            groupedOutlinedSecondary: {
                ":hover": {
                    borderColor: secondary[isDark ? 700 : 200],
                    backgroundColor: secondary[isDark ? 800 : 200]
                }
            },
            groupedTextSecondary: {
                "&:not(:last-of-type)": {
                    borderColor: secondary[300]
                }
            }
        }
    }
}