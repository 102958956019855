import {CalendarMonthOutlined, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight} from "./DatePicker";

export default function DateTimePicker(){
    return {
        defaultProps: {
            slotProps: {
                desktopPaper: {
                    sx: {
                        borderRadius: 2,
                        boxShadow: 4
                    }
                }
            },
            slots: {
                openPickerIcon:(props)=> <CalendarMonthOutlined  {...props} fontSize="small"/>,
                switchViewIcon: (props)=> <KeyboardArrowDown {...props} fontSize="small" />,
                leftArrowIcon:(props)=><KeyboardArrowLeft {...props} fontSize="small" />,
                rightArrowIcon:(props)=><KeyboardArrowRight {...props} fontSize="small" />,
            }
        }
    }
}