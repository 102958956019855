export default function Breadcrumbs(theme){
    const isDark = theme.palette.mode==='dark';
    return {
        styleOverrides: {
            separator: {
                color: theme.palette.grey[isDark ? 100 : 400],
                ":has(.dot)": {
                    width: 5,
                    height: 5,
                    borderRadius: "50%",
                    backgroundColor: theme.palette.grey[isDark ? 100 : 300]
                }
            },
            li: {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.grey[isDark ? 100 : 700]
            },
            root: {
                padding: "1rem",
                borderRadius: 10,
                border: `1px solid ${theme.palette.grey[isDark ? 700 : 100]}`
            }
        }
    }
}