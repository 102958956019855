import React, {useEffect, useState} from "react";
import ToolbarLogo from "./ToolbarLogo";
import {Avatar, Box, IconButton, Toolbar,Tooltip,} from "@mui/material";
import {toggleMode} from "../../../store/themeSlice";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import NightsStayRoundedIcon from "@mui/icons-material/NightsStayRounded";
import {useDispatch, useSelector} from "react-redux";
import ProfileMenu from "./ProfileMenu";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {ALLOW_SOUND, setTickets} from "../../../store/ticketsSlice";
import {getTickets} from "../../KanbanBoard/ticketsApi";

export default function AppToolbar({}){
    const dispatch = useDispatch();
    const modeDark = useSelector(state=>state.themeReducer.modeDark);
    const user = useSelector(state=>state.userReducer.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notificationSound,setNotificationSound] = useState(localStorage.getItem(ALLOW_SOUND)==='true');
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        const interval = setInterval(()=>{
            getTickets()
                .then(response=>{
                    if(Array.isArray(response.data?.data)){
                        dispatch(setTickets(response.data?.data));
                    }
                });
        },30000);
        return ()=>{
            clearInterval(interval);
        }
    }, []);
    useEffect(()=>{
        function handleStorage (event){
            if(event.key === ALLOW_SOUND && event.type === 'storage'){
                setNotificationSound(prevState => {
                    if(event.newValue!==String(prevState)){
                        return event.newValue==="true"
                    }
                    return prevState;
                });
            }
        }
        window.addEventListener('storage', handleStorage);
        return ()=>{
            window.removeEventListener('storage', handleStorage);
        }
    },[])
    return <Toolbar>
        <ToolbarLogo/>
        <Box sx={{flexGrow:1}}/>
        <Tooltip title={`Turn ${notificationSound?"on":"off"} notification sound`}>
            <IconButton
                aria-label={`Turn ${notificationSound?"on":"off"} notifications sound`}
                onClick={()=>{
                    setNotificationSound(prevState => {
                        localStorage.setItem(ALLOW_SOUND,!prevState);
                        return !prevState;
                    });
                }}
            >
                {notificationSound?<VolumeUpIcon />:<VolumeOffIcon/>}
            </IconButton>
        </Tooltip>
        <Tooltip title="Toggle theme">
            <IconButton
                onClick={()=>{
                    dispatch(toggleMode())
                }}
            >
                {modeDark
                    ?<WbSunnyRoundedIcon color={'warning'}/>
                    :<NightsStayRoundedIcon/>
                }
            </IconButton>
        </Tooltip>
        <Tooltip title="Profile">
            <IconButton
                size={"small"}
                onClick={handleOpen}
            >
                <Avatar sx={{ width: 30, height: 30, backgroundColor:'primary.main' }}>
                    {user?.name[0]}
                </Avatar>
            </IconButton>
        </Tooltip>
        <ProfileMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
        />
    </Toolbar>
}