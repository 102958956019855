import React from "react";
import {Menu,MenuItem,ListItemIcon,styled} from "@mui/material";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {NavLink} from "react-router-dom";
import paths from "../../../router/paths";

const ProfileMenuItem = styled(MenuItem)(({theme})=>({
    borderRadius:0,
    margin:0,
    paddingLeft:12,
}));

export default function ProfileMenu({open,handleClose,anchorEl}){
    return <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <ProfileMenuItem component={NavLink} to={paths.profile.path} onClick={handleClose}>
            <ListItemIcon>
                <AccountCircleRoundedIcon fontSize="small" />
            </ListItemIcon>
            Profile
        </ProfileMenuItem>
        <ProfileMenuItem component={NavLink} to={paths.logout.path} sx={{
            color:'error.main'
        }} onClick={handleClose}>
            <ListItemIcon >
                <LogoutRoundedIcon color={'error'} fontSize="small" />
            </ListItemIcon>
            Logout
        </ProfileMenuItem>
    </Menu>
}