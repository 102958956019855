export default function DialogContent(){
    return {
        styleOverrides: {
            dividers: {
                display: "none"
            },
            root: {
                paddingBottom: 0
            }
        }
    }
}