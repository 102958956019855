import React from "react";
import {Card, Box, Grid, Typography, Button,} from "@mui/material";
import Body from "./Body";

export default function CreateUser(){
    return <Box>
        <Card>
            <Box p={2}>
                <Typography variant={"h4"} pb={1}>
                    Create new user
                </Typography>
                <Body/>
                <Grid container justifyContent={'flex-end'}>
                    <Button>
                        Create
                    </Button>
                </Grid>
            </Box>
        </Card>
    </Box>
}