export function ObjectMap(obj,cb){
    //function returns new Object
    //with callBack function called to each Object entry
    //callBack:Function  - return entry
    //entry is array of 2 items (key and value ) callBack: [key,value]
    if(!(obj instanceof Object)){
        throw Error('obj is not instance of Object')
    }
    return Object.fromEntries(
        Object
            .entries(obj)
            .map( ( [key, val], index) => cb( [key, val], index) )
    );
}

export function ObjectFilter(obj,cb) {
    //function returns new Object
    //with callBack function called to each Object entry
    //callBack:Function  - return entry
    //entry is array of 2 items (key and value ) callBack: [key,value]
    if(!(obj instanceof Object)){
        throw Error('obj is not instance of Object')
    }
    return Object.fromEntries(
        Object.entries(obj)
            .filter( ( [key, val], index) => cb( [key, val], index) )
    );
}

export function getDiffTimeString(date){
    let today = new Date();
    let checkDate = new Date(date);
    let diffMs = (today - checkDate); 
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if(diffDays) {
        return diffDays+' d';
    }
    if(diffHrs) {
        return diffHrs+' h';
    }
    if(diffMins) {
        return diffMins+' m';
    }
    return 'Just now';
}

export function getTimeStringFromSeconds(seconds){
    
    let diffDays = Math.floor(+seconds / 86400); // days
    let diffHrs = Math.floor((+seconds % 86400) / 3600); // hours
    let diffMins = Math.round(((+seconds % 86400) % 3600) / 60); // minutes
    if(diffDays) {
        return diffDays+' d';
    }
    if(diffHrs) {
        return diffHrs+' h';
    }
    if(diffMins) {
        return diffMins+' m';
    }
    return seconds+' s';
}

export function getOS() {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (/Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}