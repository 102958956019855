import {createSlice} from "@reduxjs/toolkit";
import sound from "../assets/notification.mp3";

const soundAlert = new Audio(sound);

export const ALLOW_SOUND = 'allowSound';

export const ticketsSlice = createSlice(
    {
        name: 'tickets',
        initialState:{
            tickets:[],
        },
        reducers:{
            setTickets:(state,{payload})=>{
                if(
                    Notification.permission==='granted' 
                    && state.tickets.length 
                    && state.tickets.length<payload.length
                ){
                    localStorage.getItem(ALLOW_SOUND)==='true' && soundAlert.play();
                    new Notification(`New ticket added #${payload[payload.length-1].id}`,{
                        icon:'/logo192.png',
                        body:payload[payload.length-1].description, 
                    });
                }
                state.tickets = payload
            },
            updateTicket:(state,{payload})=>{
                state.tickets = state.tickets.map(el=>el.id===payload.id?payload:el)
            },
        }
    }
);

export const {setTickets,updateTicket} = ticketsSlice.actions;

export default ticketsSlice.reducer;