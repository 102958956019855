import axios from "axios";
import store from "./store";
import {setUser} from "./store/userSlice";
import {BASE_URL} from "./router/API";
export const UserTokenName = 'ACT';

export const authProvider = {
    isAuthenticated: undefined,
    token:localStorage.getItem(UserTokenName),
    async checkToken(){
        await axios({
            method:'GET',
            url:'user',
            baseURL:BASE_URL,
            
            headers:{
                'X-Requested-With':'XMLHttpRequest',
                'Authorization' : `Bearer ${authProvider.token}`
            },
            responseType: 'json',
        })
            .then(response=>{
                if(response.data){
                    store.dispatch(setUser(response.data));
                    authProvider.isAuthenticated = true;
                }
            })
            .catch(e=>{
                authProvider.isAuthenticated = false;
            })

    },
    async signIn(email,password) {
        await axios({
            method:'GET',
            url:'login',
            baseURL:BASE_URL,
            params:{
                email,
                password
            },
            headers:{
                'X-Requested-With':'XMLHttpRequest'
            },
            responseType: 'json',
        }).then(response=>{
            localStorage.setItem(UserTokenName, response.data.access_token);
            authProvider.token = response.data.access_token;
            authProvider.isAuthenticated = true;
            authProvider.checkToken()
        })
            .catch(e=>console.log())      
    },
    async signOut() {
        localStorage.removeItem(UserTokenName);
        authProvider.isAuthenticated = false;
    },
};