import React, {useState} from "react";
import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function Body(){
    const [passwordVisibility,setPasswordVisibility] = useState(false);
    return <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
            <TextField label={"Name"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField label={"Last Name"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField label={"User Login"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField label={"Email"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField label={"Role"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField label={"Status"} autoComplete="off" fullWidth/>
        </Grid>
        <Grid item md={6} xs={12}>
            <TextField
                label={"Password"}
                fullWidth
                autoComplete="off"
                type={passwordVisibility?"password":"text"}
                InputProps={{
                    endAdornment:<InputAdornment position={'end'}>
                        <IconButton
                            aria-label={"Toggle password visibility"}
                            onClick={()=>setPasswordVisibility(prevState => !prevState)}>
                            {passwordVisibility
                                ?<VisibilityIcon/>
                                :<VisibilityOffIcon/>
                            }
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </Grid>
    </Grid>
}