export default function AccordionSummary(theme){
    const isDark = theme.palette.mode==='dark';
    return {
        styleOverrides: {
            root: {
                fontSize: 14,
                fontWeight: 600,
                padding: "0 1.5rem",
                color: theme.palette.grey[400],
                transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                "&.Mui-expanded": {
                    color: theme.palette.grey[isDark ? 100 : 700]
                }
            },
            content: {
                alignItems: "center"
            },
            expandIconWrapper: {
                color: theme.palette.grey[400]
            }
        }
    }
}