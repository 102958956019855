export default function AccordionDetails(theme){
    return {
        styleOverrides: {
            root: {
                fontSize: 14,
                paddingTop: 4,
                fontWeight: 400,
                paddingInline: 24,
                paddingBottom: 24,
                color: theme.palette.grey[400]
            }
        }
    }
}