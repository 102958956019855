import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Grid,
    Button, 
    CardHeader,
    Card,
    LinearProgress,
} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {blue} from "@mui/material/colors";
import BoardSection from "./BoardSection";
import { DragDropContext } from "@hello-pangea/dnd";
import PriorityTitle from "./PriorityTitle";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";
import {getTickets, updateTicket} from "./ticketsApi";
import {STATUSES,PRIORITIES,TYPES} from "./options";
import {useDispatch, useSelector} from "react-redux";
import {setTickets} from "../../store/ticketsSlice";
import createOnDragEnd from "./createOnDragEnd";
import {ObjectMap} from "../../Utils";

const defaultBoardValue = {
    tickets:{},
    counters:{
        status:ObjectMap(STATUSES,([key,val])=>([val,0])),
        priority:ObjectMap(PRIORITIES,([key,val])=>([val,0])),
    }
};
PRIORITIES.forEach(priority=>{
    defaultBoardValue.tickets[priority]= {};
    STATUSES.forEach(status=>{
        defaultBoardValue.tickets[priority][status] =[]
    })
});
const BoardHeaderSx = {
    textAlign:'center',
    "& .MuiTypography-root":{
        fontSize:'1.25rem',
    }
};

export const DAY_MS = 1000 * 60 * 60 * 24;
// 7 days in milliseconds
export const WEEK_MS = DAY_MS * 7;


const initialHighestCollapse = localStorage.getItem('highestCollapse')!=='false';
const initialHighCollapse = localStorage.getItem('highCollapse')!=='false';
const initialMediumCollapse = localStorage.getItem('mediumCollapse')!=='false';
const initialLowCollapse = localStorage.getItem('lowCollapse')!=='false';

function moveTicketOnBoard(
    prevState,
    ticket,
    sourcePriority,
    sourceStatus,
    destPriority,
    destStatus,
    ID
){
    const {counters,tickets} = prevState;
    const newCounters = {
        status:{...counters.status},
        priority:{...counters.priority}
    }
    newCounters.status[sourceStatus] -=1;
    newCounters.priority[sourcePriority] -=1;
    if( (new Date() - new Date(ticket.due_date))>WEEK_MS&&(destStatus==='done'||destStatus==='declined') ){
        return {
            counters:newCounters,
            tickets:{
                ...tickets,
                [sourcePriority]: {
                    ...tickets[sourcePriority],
                    [sourceStatus]:tickets[sourcePriority][sourceStatus].filter(el=>el.id.toString()!==ID)
                }
            }
        }
    }
    newCounters.status[destStatus] +=1;
    newCounters.priority[destPriority] +=1;
    if(sourcePriority===destPriority&&sourceStatus===destStatus){
        return {
            counters:newCounters,
            tickets:{
                ...tickets,
                [sourcePriority]: {
                    ...tickets[sourcePriority],
                    [sourceStatus]:tickets[sourcePriority][sourceStatus].map(el=>el.id.toString()===ID?ticket:el)
                }
            }
        }
    }
    
    return{
        counters:newCounters,
        tickets:  {
            ...tickets,
            [sourcePriority]:{
                ...tickets[sourcePriority],
                [sourceStatus]:tickets[sourcePriority][sourceStatus].filter(el=>el.id.toString()!==ID)
            },
            [destPriority]:{
                ...tickets[destPriority],
                [destStatus]:[...tickets[destPriority][destStatus],ticket].sort(sortByDate),
                ...(sourcePriority===destPriority?{
                    [sourceStatus]:tickets[sourcePriority][sourceStatus].filter(el=>el.id.toString()!==ID)
                }:{})
            }
        }
    }
}

function sortByDate(t1,t2){
    const now = new Date();
    return (new Date(t1.due_date) - now) - (new Date(t2.due_date) - now)
}

function getMaxTickets(number){
    return number<=9999?number:'9999+'
}

export default function KanbanBoard(){
    const [highestCollapse,setHighestCollapse] = useState(initialHighestCollapse);
    const [highCollapse,setHighCollapse] = useState(initialHighCollapse);
    const [mediumCollapse,setMediumCollapse] = useState(initialMediumCollapse);
    const [lowCollapse,setLowCollapse] = useState(initialLowCollapse);
    // const [openNewTicket,setOpenNewTicket] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [editedTicket,setEditedTicket] = useState({});
    const [forceStatus,setForceStatus] = useState('');
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        localStorage.setItem('highestCollapse',highestCollapse);
        localStorage.setItem('highCollapse',highCollapse);
        localStorage.setItem('mediumCollapse',mediumCollapse);
        localStorage.setItem('lowCollapse',lowCollapse);
    }, [highestCollapse,highCollapse,mediumCollapse,lowCollapse]);
    const [boardValue,setBoardValue] = useState(defaultBoardValue);
    const handleEditDialog = useCallback((ticket)=>{
        setOpenEdit(true);
        setEditedTicket(ticket);
    },[]);
    const tickets = useSelector(store => store.ticketsReducer.tickets);
    const dispatch = useDispatch();
    useEffect(() => {
        if(!openEdit){
            setEditedTicket({});
        }
    }, [openEdit]);

    useEffect(() => {
        setLoading(true);
        getTickets()
            .then(response=>{
                if(Array.isArray(response.data?.data)){
                    dispatch(setTickets(response.data?.data));
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    }, []);
    useEffect(() => {
        const value = {};
        const counters = {
            status:ObjectMap(STATUSES,([key,val])=>([val,0])),
            priority:ObjectMap(PRIORITIES,([key,val])=>([val,0])),
        };
        PRIORITIES.forEach(priority=>{
            value[priority]= {};
            STATUSES.forEach(status=>{
                value[priority][status] =[]
            })
        });
        if(Array.isArray(tickets)){
            tickets.forEach(ticket=>{
                if(
                    value.hasOwnProperty(ticket.priority)&&
                    value[ticket.priority].hasOwnProperty(ticket.status) 
                    && !( ( new Date() - new Date(ticket.due_date) )>WEEK_MS
                        && (ticket.status==='done'||ticket.status==='declined') 
                    )
                ){
                    counters.status[ticket.status] +=1;
                    counters.priority[ticket.priority] +=1;
                    value[ticket.priority][ticket.status].push(ticket);
                    value[ticket.priority][ticket.status].sort(sortByDate)
                }
            });
        }
        setBoardValue(({counters,tickets:value}))
    }, [tickets]);
    const handleUpdatedTicket = useCallback((editedTicket,oldTicket)=>{
        
        setBoardValue(prevState => {
            
            return moveTicketOnBoard(
                prevState,
                editedTicket,
                oldTicket.priority,
                oldTicket.status,
                editedTicket.priority,
                editedTicket.status,
                oldTicket.id.toString()
            )
        })
    },[]);
    // overflowX:'auto' causes `unsupported nested scroll container detected` error but every thing seems to be ok
    return <>
        <Box sx={{position:'relative'}}>
            {loading&&<LinearProgress sx={{position: 'absolute', width: '100%'}}/>}
        </Box>
        <Box sx={{overflowX:'auto',overflowY:'hidden',minHeight:`calc(100vh - 64px)`,px:'2px'}}>
            <Box sx={{width:`calc(100% - 4px)`}}>
                <DragDropContext onDragEnd={createOnDragEnd(
                    updateTicket,
                    setBoardValue,
                    setOpenEdit,
                    setEditedTicket,
                    setForceStatus,
                    moveTicketOnBoard,
                    boardValue,
                )}>
                    <Grid container spacing={2} >
                    <Grid item xs={12}>
                    {/*    <Button color={"success"} onClick={()=>setOpenNewTicket(true)} endIcon={<AddRoundedIcon/>}>*/}
                    {/*        Create New Task */}
                    {/*    </Button>*/}
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid
                            item
                            container
                            sx={{
                                minWidth:1164,
                                flexBasis:1164,
                            }} 
                            spacing={2}
                        >
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs>
                                    <Card sx={(theme)=>({
                                        background:theme.palette.info.main,
                                        color:theme.palette.info.contrastText,
                                    })}>
                                       <CardHeader sx={BoardHeaderSx} title={`Todo (${getMaxTickets(boardValue.counters.status.todo)})`}/>
                                   </Card>
                                </Grid>
                                <Grid item xs>
                                    <Card sx={(theme)=>({
                                        background:theme.palette.primary.main,
                                        color:theme.palette.primary.contrastText,
                                    })}>
                                        <CardHeader sx={BoardHeaderSx} title={`In process (${getMaxTickets(boardValue.counters.status.in_process)})`}/>
                                    </Card>
                                </Grid>
                                <Grid item xs>
                                    <Card sx={(theme)=>({
                                        background:theme.palette.warning.main,
                                        color:theme.palette.warning.contrastText,
                                    })}>
                                        <CardHeader sx={BoardHeaderSx} title={`In review (${getMaxTickets(boardValue.counters.status.in_review)})`}/>
                                    </Card>
                                </Grid>
                                <Grid item xs>
                                    <Card sx={(theme)=>({
                                        background:theme.palette.success.main,
                                        color:theme.palette.success.contrastText,
                                    })}>
                                        <CardHeader sx={BoardHeaderSx} title={`Done (${getMaxTickets(boardValue.counters.status.done)})`}/>
                                    </Card>
                                </Grid>
                                <Grid item xs>
                                    <Card sx={(theme)=>({
                                        background:theme.palette.error.main,
                                        color:theme.palette.error.contrastText,
                                    })}>
                                        <CardHeader sx={BoardHeaderSx} title={`Declined (${getMaxTickets(boardValue.counters.status.declined)})`}/>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <PriorityTitle
                                    collapse={highestCollapse}
                                    setCollapse={setHighestCollapse}
                                    name={"Highest"}
                                    counter={boardValue.counters.priority.highest}
                                    CircleRoundedIconProps={{
                                        color:'error'
                                    }}
                                />
                                <Grid item xs={12}>
                                    <BoardSection
                                        collapseState={highestCollapse}
                                        board={boardValue.tickets}
                                        priority="highest"
                                        handleEditDialog={handleEditDialog}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <PriorityTitle
                                    collapse={highCollapse}
                                    setCollapse={setHighCollapse}
                                    name={"High"}
                                    counter={boardValue.counters.priority.high}
                                    CircleRoundedIconProps={{
                                        color:'warning'
                                    }}
                                />
                                <Grid item xs={12}>
                                    <BoardSection
                                        collapseState={highCollapse}
                                        board={boardValue.tickets}
                                        priority="high"
                                        handleEditDialog={handleEditDialog}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <PriorityTitle
                                    collapse={mediumCollapse}
                                    setCollapse={setMediumCollapse}
                                    name={"Medium"}
                                    counter={boardValue.counters.priority.medium}
                                    CircleRoundedIconProps={{
                                        color:'success'
                                    }}
                                />
                                <Grid item xs={12}>
                                    <BoardSection
                                        collapseState={mediumCollapse}
                                        board={boardValue.tickets}
                                        priority="medium"
                                        handleEditDialog={handleEditDialog}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <PriorityTitle
                                    collapse={lowCollapse}
                                    setCollapse={setLowCollapse}
                                    name={"Low"}
                                    counter={boardValue.counters.priority.low}
                                    CircleRoundedIconProps={{
                                        sx: {
                                            color:blue[600]
                                        }
                                    }}
                                />
                                <Grid item xs={12}>
                                    <BoardSection
                                        collapseState={lowCollapse}
                                        board={boardValue.tickets}
                                        priority="low"
                                        handleEditDialog={handleEditDialog}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </DragDropContext>
            </Box>
        {/*<CreateDialog  */}
        {/*    open={openNewTicket}*/}
        {/*    onClose={()=>setOpenNewTicket(false)}*/}
        {/*/>*/}
        <EditDialog
            open={openEdit}
            onClose={()=> {
                setOpenEdit(false);
            }}
            ticket={editedTicket}
            setEditedTicket={handleUpdatedTicket}
            forceStatus={forceStatus}
        />
    </Box></>;
}