import {SvgIcon} from "@mui/material";

export default function Autocomplete(){
    return {
        defaultProps: {
            popupIcon: <ExpandMore/>,
            slotProps: {
                paper: {
                    sx: {
                        marginTop: 1,
                        borderRadius: 2
                    }
                }
            }
        },
        styleOverrides: {
            option: {
                padding: 10,
                fontSize: 14,
                borderRadius: 8,
                marginInline: 10
            },
            tag: {
                maxWidth: 130
            }
        }
    }
}

const ExpandMore = (props)=><SvgIcon {...props}>
    <path
        d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"
    />
</SvgIcon>