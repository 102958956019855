import React ,{useState,useEffect} from 'react';
import {
    Grid,
    Typography, 
    Box, 
    IconButton, 
    TextField,
    InputAdornment
} from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export default function Header({Title,setSelected,setUnselected,setAllElements,getSearch,printWithId}){
    const [search,setSearch] = useState('');
    useEffect(()=>{
        searchElement();
        getSearch(search);
    },[search]);
    function searchElement() {
        // compare element name and search string in lower case
        const filterSearch = prevState => prevState.map(el => {

            return {
                ...el,
                inFiltered: (printWithId ? `[${el.id}] ${el.name}` : el.name).toLocaleLowerCase().includes(search.toLocaleLowerCase())
            }
        });
        setSelected(filterSearch);
        setUnselected(filterSearch);
        setAllElements(filterSearch);
    }
    return <Grid 
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={6}
        pb={1}
    >
        <Grid 
            item
            sm={6}
            xs={12}
        >
            <Typography variant={'h4'}>{Title}</Typography>
        </Grid>
        <Grid 
            item
            container
            justify={"flex-end"}
            sm={6}
            xs={12}
        >
            <Box sx={{ml:'auto'}}>
                <TextField
                    placeholder="Search…"
                    value={search}
                    onKeyUp={(e)=>e.keyCode === 13&&searchElement()}
                    onChange={(event)=>setSearch(event.target.value)}
                    inputProps={{ 'aria-label': 'search' }}
                    InputProps={{
                        endAdornment:<InputAdornment position={'end'}>
                            <IconButton onClick={searchElement}>
                                <SearchRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </Box>
        </Grid>
    </Grid>
}