import React, {useCallback} from "react";
import DefaultElement from "./DefaultElement";
import CodeElement from "./CodeElement";
import BlockquoteElement from "./BlockquoteElement";
import LinkElement from "./LinkElement";

export default function useRenderElements(){
    return useCallback((props)=>{
        switch (props.element.type){
            case 'link':
                return <LinkElement {...props} />
            case 'code_block':
                return <CodeElement {...props} />
            case 'code_line':
                return <div {...props.attributes}>
                    {props.children}
                </div>
            case 'block_quote':
                return <BlockquoteElement {...props} />
            case 'ul_list':
                return <ul style={{paddingLeft:20}} {...props.attributes}>
                    {props.children}
                </ul>
            case 'list_item':
                return <li {...props.attributes}>
                    {props.children}
                </li>
            case 'ol_list':
                return (
                    <ol style={{paddingLeft:20}} {...props.attributes}>
                        {props.children}
                    </ol>
                )
            default:
                return <DefaultElement {...props} />
        }
    },[]);
}