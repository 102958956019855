import React, {useState} from "react";
import {
    Grid, 
    Box,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Button,
    Checkbox,
    Tooltip,
} from "@mui/material";
import cover from "../../assets/login-cover.jpeg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {authProvider} from "../../authProvider";
import {useNavigate} from "react-router-dom";

export default function Login(){
    const navigate = useNavigate();
    const [togglePass,setTogglePass] = useState(false);
    const [form,setForm] = useState({
        email:'',
        password:'',
    });
    return <Grid container sx={{height:'100vh'}}>
        <Grid item xs={12} md={6}>
            <Box
                sx={{ 
                    backgroundImage:` url("${cover}")`,
                    backgroundPositionX:"left",
                    backgroundPositionY:'center',
                    backgroundRepeat:'no-repeat',
                    height:'100%'
                }}
            >
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box sx={(theme)=>({
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:theme.palette.grey[800],
                height:'100%'
            })}>
                <Box sx={{p:4,height:550}}>
                    <Typography sx={{
                        fontSize:30,
                        fontWeight:600,
                        lineHeight:1,
                        pb:4
                    }} >Sign In</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField  
                                placeholder={"Email"} 
                                fullWidth                          
                                value={form.email}
                                onChange={(e)=>{
                                    setForm(prevState => ({
                                        ...prevState,
                                        email: e.target.value
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                placeholder={"Password"}
                                value={form.password}
                                type={togglePass?'text':'password'}
                                onChange={(e)=>{
                                    setForm(prevState => ({
                                        ...prevState,
                                        password: e.target.value
                                    }))
                                }}
                                InputProps={{endAdornment:<InputAdornment position={"end"}>
                                    <Tooltip title={togglePass?'Hide password':'Show password'}>
                                        <IconButton
                                            onClick={()=>{
                                                setTogglePass(prev=>!prev);
                                            }} 
                                            edge="end"
                                            size={"small"}
                                            sx={theme=>({
                                                color:theme.palette.grey[400]
                                            })}
                                        >
                                            {togglePass
                                                ?<VisibilityOffIcon color={"disabled"} fontSize={"small"} />
                                                :<VisibilityIcon color={"disabled"} fontSize={"small"} />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>}}
                            />
                            {/*<Box sx={{my:1,display:'flex',justifyContent:'space-between',alignItems:'center'}}>*/}
                            {/*    <Box sx={{*/}
                            {/*        display:'flex',*/}
                            {/*        gap:1,*/}
                            {/*        alignItems:'center'*/}
                            {/*    }}>*/}
                            {/*        <Checkbox*/}
                            {/*            size={"medium"}*/}
                            {/*            defaultChecked*/}
                            {/*            sx={{*/}
                            {/*                display: 'inline-flex',*/}
                            {/*                alignItems: 'center',*/}
                            {/*                justifyContent: 'center',*/}
                            {/*                position: 'relative',*/}
                            {/*                boxSizing: 'border-box',*/}
                            {/*                outline: 0,*/}
                            {/*                border: 0,*/}
                            {/*                margin: 0,*/}
                            {/*                cursor: 'pointer',*/}
                            {/*                userSelect: 'none',*/}
                            {/*                verticalAlign: 'middle',*/}
                            {/*                appearance: 'none',*/}
                            {/*                padding: 0*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*        <Box sx={{fontSize:14,fontWeight:500}}>*/}
                            {/*            Remember me*/}
                            {/*        </Box>*/}
                            {/*    </Box>*/}
                            {/*    <Box sx={(theme)=>({*/}
                            {/*        fontSize: 13,*/}
                            {/*        fontWeight: 500,*/}
                            {/*        color:theme.palette.error[500]*/}
                            {/*    })}>*/}
                            {/*        Forget Password?*/}
                            {/*    </Box>*/}
                            {/*</Box>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant={"contained"}
                                fullWidth
                                onClick={async ()=>{
                                    await authProvider.signIn(form.email,form.password);
                                    navigate('/')
                                }}
                            >
                                Sing in
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    </Grid>
}