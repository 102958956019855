import {FormControl, InputLabel, MenuItem, Select,IconButton,InputAdornment,Tooltip} from "@mui/material";
import React from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function FilterSelect({name,filter,setFilter,options,renderOptionFn}){
    return <FormControl size={"small"} sx={{mt:1}} fullWidth>
        <InputLabel id={`select-${name}-label`} sx={{textTransform:'capitalize'}}>{name}</InputLabel>
        <Select
            labelId={`select-${name}-label`}
            id={`select-${name}`}
            label={name}
            multiple
            sx={{
                "& .MuiInputBase-input.MuiInputBase-inputAdornedEnd.MuiSelect-select": {
                    pr:0
                }
            }}
            value={filter[name]}
            onChange={(e)=>{
                setFilter(prevState => ({...prevState,[name]:e.target.value}))
            }}
            endAdornment={<InputAdornment position={'end'} sx={{mr:1}}>
                <Tooltip title={'Clear filter'} arrow>
                    <IconButton 
                        size={"small"} 
                        onClick={()=>{
                            setFilter(prevState => ({...prevState,[name]:[]}))
                        }}
                    >
                        <CloseRoundedIcon fontSize={"small"} />
                    </IconButton>
                </Tooltip>
            </InputAdornment>}
        >
            {
                options.map(option=><MenuItem key={option} value={option}>
                    {renderOptionFn(option)}
                </MenuItem>)
            }
        </Select>
    </FormControl>
}