import {Box, Card, Chip, IconButton, Tooltip} from "@mui/material";
import React from "react";
import {useOutletContext} from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ToggleTicketsList from "./ToggleTicketsList";

export default function EmptyChat(){
    const [collapseList,setCollapseList] = useOutletContext();
    return <Card >
        <Box sx={{
            overflowY:'auto',
            height:`calc(100vh - 180px)`,
            width:'100%',
            display:'flex',
            flexDirection:'center',
            alignItems:'center',
            position:'relative'
        }}>
           <ToggleTicketsList
                collapseList={collapseList}
                setCollapseList={setCollapseList}
           />
            
            <Chip sx={{
                mx:'auto'
            }} label={"Select chat to start!"} />
        </Box>
    </Card>
}