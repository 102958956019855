import {SvgIcon} from "@mui/material";

export default function Radio(){
    return {
        defaultProps: {
            icon: <RadioButtonIcon/>,
            checkedIcon: <RadioButtonChecked/>,
        },
        styleOverrides: {
            root: {
                padding: 6
            }
        },
        variants: [{
            props: {
                size: "large"
            },
            style: {
                ".MuiSvgIcon-root": {
                    fontSize: "1.75rem"
                }
            }
        }]
    }
}

const RadioButtonIcon = (pros)=>(
    <SvgIcon {...pros}>
        <path
            d="M12 22.002c5.524 0 10.002-4.478 10.002-10.001 0-5.524-4.478-10.002-10.002-10.002-5.524 0-10.002 4.478-10.002 10.002 0 5.523 4.478 10.001 10.002 10.001Zm0-1.5A8.502 8.502 0 1 1 12 3.5a8.502 8.502 0 0 1 0 17.003Z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </SvgIcon>
)

const RadioButtonChecked = (props) =>(
    <SvgIcon {...props}>
        <path
            fill="currentColor"
            fillRule="nonzero"  
            d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10.001-10.002 10.001-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999Zm0 1.5a8.502 8.502 0 1 0 0 17.003A8.502 8.502 0 0 0 12 3.5Zm-.003 2.5a5.998 5.998 0 1 1 0 11.996 5.998 5.998 0 0 1 0-11.996Z"
        />
    </SvgIcon>
)