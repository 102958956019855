export default function LoadingButton(theme){
    return {
        defaultProps: {
            variant: "contained"
        },
        styleOverrides: {
            root: {
                ".MuiLoadingButton-loadingIndicator": {
                    color: theme.palette.grey[400]
                }
            }
        }
    }
}