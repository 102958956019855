import {getOS} from "../../../../../Utils";
import {Typography} from "@mui/material";
import React from "react";

const OS =getOS();

const isCommand = OS ==='Mac OS';

const isCtrl = OS ==='Windows'||OS ==='Linux';

const getMod =(key)=>(isCommand||isCtrl)&&<><br/><b>{isCtrl?'Ctrl':'⌘'} {key}</b></>

const TooltipText =({children})=><Typography textAlign={'center'} fontSize={14}>{children}</Typography>
export const markTooltips = {
    bold:(<TooltipText>Bold{getMod('B')}</TooltipText>),
    italic:(<TooltipText>Italic{getMod('I')}</TooltipText>),
    strikeThrough:(<TooltipText>Strikethrough{getMod('Shift X')}</TooltipText>),
    code:(<TooltipText >Code{getMod('Shift C')}</TooltipText>)
}

export const linkTooltip = <TooltipText>Link{getMod('Shift U')}</TooltipText>

export const blockTooltips = {
    code_block:(<TooltipText>Code block{getMod('Option Shift C')}</TooltipText>),
    block_quote:(<TooltipText>Blockquote{getMod('Shift 9')}</TooltipText>),
    ul_list:(<TooltipText>Bulleted list{getMod('Shift 8')}</TooltipText>),
    ol_list:(<TooltipText >Ordered list{getMod('Shift 7')}</TooltipText>)
}