import {styled} from "@mui/material";
import React from "react";

const Code = styled('code')(({theme})=>({
    border: '1px solid',
    padding: '2px 4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.dark
}));


const Leaf = ({attributes,children,leaf}) => {
    if (leaf.bold) {
        children = <b>{children}</b>
    }
    if (leaf.code) {
        children = <Code>{children}</Code>
    }
    if (leaf.italic) {
        children = <em>{children}</em>
    }
    if (leaf.strikeThrough) {
        children = <del>{children}</del>
    }
    return <span 
        {...attributes}
        style={{paddingLeft: '0.1px'}}
    >{children}</span>
};

export default Leaf;