import React from "react";
import {Collapse, Grid} from "@mui/material";
import BoardColumn from "./BoardColumn";

export default function BoardSection(
    {
        collapseState,
        board,
        priority,
        handleEditDialog
    }){
    return <Collapse sx={{width:'calc(100% - 4px)'}} unmountOnExit={true} in={collapseState}>
        <Grid item container xs={12} spacing={2}>
            <Grid item xs>
                <BoardColumn 
                    droppableId={`${priority}-todo`} 
                    childArray={board[priority]['todo']}
                    handleEditDialog={handleEditDialog}
                />
            </Grid>
            <Grid item xs>
                <BoardColumn 
                    droppableId={`${priority}-in_process`}
                    childArray={board[priority]['in_process']}
                    handleEditDialog={handleEditDialog}
                />
            </Grid>
            <Grid item xs>
                <BoardColumn
                    droppableId={`${priority}-in_review`}
                    childArray={board[priority]['in_review']}
                    handleEditDialog={handleEditDialog}
                />
            </Grid>
            <Grid item xs>
                <BoardColumn 
                    droppableId={`${priority}-done`}
                    childArray={board[priority]['done']}
                    handleEditDialog={handleEditDialog}
                />
            </Grid>
            <Grid item xs>
                <BoardColumn 
                    droppableId={`${priority}-declined`}
                    childArray={board[priority]['declined']}
                    handleEditDialog={handleEditDialog}
                />
            </Grid>
        </Grid>
    </Collapse>
}