import React,{useMemo} from "react";

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

export const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{fontSize:0}}
    >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

export default  function LinkElement  ({ attributes, children, element })  {

    const safeUrl = useMemo(() => {
        let parsedUrl = null
        try {
            parsedUrl = new URL(element.link)
            // eslint-disable-next-line no-empty
        } catch {}
        if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
            return parsedUrl.href
        }
        return 'about:blank'
    }, [element.link])

    return (
        <a
            {...attributes}
            title={safeUrl}
            href={safeUrl}
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}