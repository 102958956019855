import React, {useEffect, useMemo} from "react";
import {useSelector,useDispatch} from "react-redux";
import {setMode} from "../store/themeSlice";
import darkTheme from "./darkTheme";
import lightTheme from "./lightTheme";
import {ThemeProvider} from "@mui/material/styles";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import CssBaseline from "@mui/material/CssBaseline";

/**
 * Wrapper which provides theme to children components
 * */
export default function ToggleThemeModeProvider({children}){
    const dispatch = useDispatch();
    const currentModeDark = useSelector(state=>state.themeReducer.modeDark);
    const forceDark = useSelector(state=>state.themeReducer.forceDark);
    useEffect(()=>{
        function handleStorageChange(){
            const mode = JSON.parse(localStorage.getItem('modeDark'));
            dispatch(setMode(mode));
        }
        window.addEventListener("storage",handleStorageChange);
        return ()=>{
            window.removeEventListener("storage",handleStorageChange);
        }
    },[]);
    useEffect(()=>{
        const mode = JSON.parse(localStorage.getItem('modeDark'));
        if(mode!==currentModeDark){
            localStorage.setItem('modeDark',currentModeDark);
        }
    },[currentModeDark]);
    const theme = useMemo(()=>currentModeDark||forceDark?darkTheme:lightTheme,[currentModeDark,forceDark]);
    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        {children}
    </ThemeProvider>
}