export default function MenuItem(){
    return {
        styleOverrides: {
            root: {
                fontSize: 14,
                borderRadius: 8,
                marginInline: 8
            }
        }
    }
}