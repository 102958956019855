import React from "react"
import {getPriority} from "../KanbanBoard/BoardTicketCard";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

export default  React.forwardRef(function PriorityIcon({priority,...rest},ref){
    return <CircleRoundedIcon
        {...rest}
        ref={ref}
        sx={(theme)=>({fontSize:12,color:getPriority(theme,priority)})}
    />
})