export default function DialogTitle(){
    return {
        styleOverrides: {
            root: {
                padding: 24,
                fontWeight: 600,
                paddingBottom: 16
            }
        }
    }
}