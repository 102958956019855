import {alpha} from "@mui/material/styles";

export default function Backdrop(theme){
    const start = alpha(theme.palette.grey[600], .8)
        , end = alpha(theme.palette.grey[700], .4);
    return {
        styleOverrides: {
            root: {
                backgroundColor: "transparent",
                background: [alpha(theme.palette.grey[900], .5), `linear-gradient(90deg, ${start} 0%, ${end} 100%)`, `-moz-linear-gradient(90deg, ${start} 0%, ${end} 100%)`, `-webkit-linear-gradient(90deg, ${start} 0%, ${end} 100%)`]
            },
            invisible: {
                background: "transparent"
            }
        }
    }
}