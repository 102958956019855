import React, {useMemo, useState} from "react";
import {
    Card,
    Box,
    Typography,
    Button,
    TextField,
    IconButton,
    Dialog,
    DialogContent, 
    DialogTitle, 
    Grid, 
    DialogActions
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TransitionListView from "../TransitionList";
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';

const initialAllElements = {
    1:'Sam Smith', 
    2:'John Week', 
    3:'Terry Crew', 
    4:'Elizabeth Suon', 
    5:'Jonny Depp',
    6:'Will Smmmmmmmmmmmmmmmmmmmmmmmmmmmvmvmvmvmvmvmvmmmmmmmmmmith',
    7:'Jonny Depp',
    8:'Jonny Depp',
    9:'Jonny Depp',
    10:'Jonny Depp',
    11:'Jonny Depp',
};

const rows = [
    {
        id:1,
        name:'create',
        display_name:'Create',
        description:'Can create',
        action:['edit']
    },
    {
        id:2,
        name:'read',
        display_name:'Read',
        description:'Can read',
        action:['edit']
    },
    {
        id:3,
        name:'update',
        display_name:'Update',
        description:'Can update',
        action:['edit']
    },
    {
        id:4,
        name:'delete',
        display_name:'Delete',
        description:'Can delete',
        action:['edit']
    },
];

export default function Permissions(){
    const [open,setOpen] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const columns = useMemo(()=>[
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'display_name', headerName: 'Display name', width: 150 },
        { field: 'description',headerName: 'Description',flex:1,minWidth:150},
        { field: 'action', headerName: 'Action', width: 100,headerAlign:'center',
            renderCell:()=>(<Box
                display={'flex'}
                onClick={(e)=>{
                    e.stopPropagation();
                }} 
                width={'100%'}
                justifyContent={'center'}
            >
                <IconButton
                    size={'small'}
                    onClick={()=>{
                        setOpenEdit(prevState => !prevState)
                    }}
                >
                    <EditRoundedIcon fontSize={'small'} />
                </IconButton>
                <IconButton
                    size={'small'} 
                    onClick={()=>{
                        setOpen(prevState => !prevState)
                    }}
                >
                    <LockPersonRoundedIcon fontSize={'small'} />
                </IconButton>
            </Box>)
        },
    ],[]);
    return <Box>
        <Dialog open={open} sx={{
            '& .MuiPaper-root': {
                width:900,
                maxWidth:'100%',
            }
        }} onClose={()=>setOpen(false)}>
            <DialogContent>
                <TransitionListView
                    Title={"Sync permissions"}
                    printWithId
                    elevation={0}
                    initialAllElements={initialAllElements}
                />
            </DialogContent>
        </Dialog>
        <Dialog open={openEdit} onClose={()=>setOpenEdit(false)}>
            <DialogTitle>
                Edit permission
            </DialogTitle>
            <DialogContent sx={{minWidth:400}}>
                <Grid container py={2} spacing={2}>
                    <Grid item xs={12}>
                        <TextField label={"Display name"} fullWidth  />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={"Description"} multiline rows={3} fullWidth  />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={()=>setOpenEdit(false)}>
                    Close
                </Button>
                <Button color={"success"} onClick={()=>setOpenEdit(false)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        <Card>
            <Box py={2}>
                <Box px={2}>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}>
                        <Typography fontSize={18} fontWeight={600}>
                            Permissions
                        </Typography>
                    </Box>
                    <Box display={'flex'} gap={2} pt={2} flexWrap={'wrap'}>
                        <TextField
                            label={'ID'}
                        />
                        <TextField
                            label={'Name'}
                        />
                        <TextField
                            label={'Display name'}
                        />
                        <TextField
                            label={'Description'}
                        />
                        <Button endIcon={<FilterAltRoundedIcon/>}>
                            Filter
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    height:`calc(100vh - 300px)`,
                    minHeight:400,
                    pt:2,
                    width:'100%'
                }}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        checkboxSelection
                        sx={{
                            '& .MuiDataGrid-main>*:first-of-type':{
                                borderRadius:0
                            }
                        }}
                    />
                </Box>
            </Box>
        </Card>
    </Box>
}