import React from "react";

const BlockquoteElement = ({attributes,children}) =>(
    <blockquote style={{borderLeft:'3px solid'}} {...attributes}>
        <div style={{paddingLeft:10}}>
            {children}
        </div>
    </blockquote>
)

export default BlockquoteElement;