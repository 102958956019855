import React, {useEffect} from "react";
import {Avatar, Box, Card, Chip, Typography} from "@mui/material";
import {useSelector,useDispatch} from "react-redux";
import {getTeamsList} from "./messengerApi";
import {setTeams} from "../../store/optionsSlice";

export default function SpaceSelectorHeader({exclude,setExclude}){
    
    const teams = useSelector(state=> state.optionsReducer.teams);

    const dispatch = useDispatch();
    useEffect(() => {
        if(!teams.length){
            getTeamsList()
                .then(response=>{
                    if(response.data?.data){
                        dispatch(setTeams(response.data.data))
                    }
                })
        }
    }, [teams]);
    return <Card >
        <Box p={3} sx={{display:'flex',gap:1,justifyContent:'flex-start',alignItems:'center'}}>
            <Box>
                <Typography fontWeight={700}>
                    Select spaces:
                </Typography>
            </Box>
            {teams.length&& teams.map(team=>{
                return  <Chip
                    key={team.id}
                    label={team.name}
                    icon={<Avatar src={team.icon} sx={{width:22,height:22}} variant="rounded" />}
                    clickable
                    sx={{
                        borderRadius:2
                    }}
                    onClick={()=>{
                        setExclude(prev=>prev.includes(team.id)
                            ?prev.filter(el=>el!==team.id)
                            :[...prev,team.id]
                        )
                    }}
                    variant={exclude.includes(team.id)?"outlined":"contained"}
                />
            })}
        </Box>
    </Card>
}