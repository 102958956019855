import React ,{createRef,useState,useMemo,useEffect} from "react";
import {FixedSizeList} from "react-window";
import {
    Box,
    Paper,
    IconButton,
    Tooltip,
    Typography,
    Button,
    Grid,
} from "@mui/material";
import ListOverlay from "./ListOverlay";
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import TransitionListItem from "./TransitionListItem";

const LIST_PADDING_VERTICAL = 8;
export default function SelectList(
    {
        itemList,
        buttonText,
        onButtonClick,
        onItemClick,
        headerText,
        slide="left",
        isFiltered,
        onSortClick,
        loading,
        printWithId,
        sortByName,
        animateItems,
        setAnimateItems,
        elevation
    }
){
    const refContainer = createRef();
    const refHeader = createRef();
    const refButton = createRef();
    const [height,setHeight] = useState(410);
    const itemsList = useMemo(()=>{
        const newArray = [];
        itemList.forEach((element,index)=> {
            if (element.inFiltered) {
                newArray.push(<TransitionListItem 
                    element={element} 
                    slide={slide}
                    setAnimateItems={setAnimateItems}
                    printWithId={printWithId}
                    onItemClick={onItemClick}
                    isFiltered={isFiltered}
                />)
            }
        });
        return newArray
    },[itemList,isFiltered,printWithId,onItemClick]);
    const itemData = useMemo(()=>{
        return {itemsList,animateItems}
    },[itemsList,animateItems]);

    useEffect(()=>{
        if(refContainer.current&&refButton.current&&refHeader.current){
            //height of list equals  container - ( header + button )
            setHeight(refContainer.current.offsetHeight-
                (
                    refHeader.current.offsetHeight 
                    + refButton.current.offsetHeight 
                    + (LIST_PADDING_VERTICAL*3)
                )
            );
        }
    },[itemsList.length]);
    return <Grid
        item
        md={6}
        sm={12}
        xs={12}
    >
        <Paper
            elevation={elevation}
            sx={(theme)=>({
                height:540,
                position:'relative',
                overflow:'hidden',
                p:1,
                [theme.breakpoints.down('sm')]:{
                    height:408,
                },
                [theme.breakpoints.down('xs')]:{
                    height:304,
                }
            })}
            ref={refContainer}
        >
            {loading && <ListOverlay/>}
            <Box
                ref={refHeader}
                sx={({
                    padding:2,
                    position:'relative',
                    display:'flex',
                    zIndex:'2',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    flexWrap:'nowrap',
                    alignItems:'center',
                })}
            >
                <Typography>
                    {headerText}
                </Typography>
                <Tooltip title={`Sort by ${sortByName?'Name':'Id'}`}>
                    <IconButton
                        onClick={onSortClick}
                        size={"small"}
                    >
                        <SortRoundedIcon/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Box
                sx={(theme)=>({
                    overflow: 'hidden',
                })}
            >
                <FixedSizeList
                    itemData={itemData}
                    height={height}
                    width={'100%'}
                    itemSize={52}
                    itemCount={itemsList.length}
                    style={{
                        overflowX:'hidden'
                    }}
                >
                    {renderRow}
                </FixedSizeList>
            </Box>
            <Button
                ref={refButton}
                fullWidth
                color={"primary"}
                variant={"contained"}
                sx={(theme)=>({
                    mt:1,
                    zIndex:6,
                    boxShadow:2,
                })}
                onClick={onButtonClick}
            >
                {buttonText}
            </Button>
        </Paper>
    </Grid>
}

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data.itemsList[index], {
        style: {
            ...style,
        },
        animateItems:data.animateItems,
    });
}
