import React from "react";
import {
    Box,
    ListItemButton,
    ListItemText,
    Slide, 
    styled
} from "@mui/material";

const ListItemTextOverflow = styled(ListItemText)(({theme})=>({
    ['&>.MuiTypography-root']:{
        textOverflow:'ellipsis',
        overflow:'hidden',
    }
}));

export default function TransitionListItem(
    {
        style,
        animateItems,
        element,
        slide,
        setAnimateItems,
        printWithId,
        onItemClick,
        isFiltered,
    }){
    return (<Box key={element.id} style={style}>
        <ListItemButton
            sx={(theme)=>({
                borderRadius:theme.spacing(.5),
                height: 48,
                boxSizing:'border-box',
                whiteSpace:'nowrap',
                marginBottom:4,
                boxShadow:theme.shadows[1],
                backgroundColor: theme.palette.background.paper,
                border:`1px solid ${theme.palette.action.disabledBackground}`,
                ...(isFiltered&&{
                    border:`1px solid ${theme.palette.primary.main}`,
                    boxSizing:'border-box',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow:theme.shadows[1],
                })
            })}
            onClick={()=>{
                setAnimateItems(prevState=>{
                    if(!prevState.includes(element.id&&slide==="right")){
                        return [...prevState,element.id]
                    }
                    return prevState
                })
                onItemClick( element.id)
            }}
        >
            {  animateItems.includes(element.id) ?
                <Slide 
                    direction={slide} 
                    in={animateItems.includes(element.id)}
                    onEntered={()=>{setAnimateItems(prevState=>prevState.filter(id=>id!==element.id))}}
                >
                    <ListItemTextOverflow>
                        {printWithId ? `[${element.id}] ${element.name}` : element.name}
                    </ListItemTextOverflow>
                </Slide>
                :
                <ListItemTextOverflow>
                    {printWithId ? `[${element.id}] ${element.name}` : element.name}
                </ListItemTextOverflow>
            }
        </ListItemButton>
    </Box>)
}