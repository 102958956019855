export default function Tabs(theme){
    const isDark = theme.palette.mode==='dark';
    return {
        styleOverrides: {
            flexContainer: {
                gap: "2rem"
            },
            scrollButtons: {
                "&.Mui-disabled": {
                    opacity: .2
                }
            },
            root: {
                minHeight: 45,
                borderBottom: `1px solid ${theme.palette.grey[isDark ? 700 : 100]}`
            },
            indicator: ({ownerState: t})=>({
                ...(t == null ? void 0 : t.indicatorColor) === "secondary" ? {
                    backgroundColor: theme.palette.text.primary
                } : {}
            })
        }
    }
}