import {SvgIcon} from "@mui/material";

export default function DatePicker(){
    return {
        defaultProps: {
            slots: {
                openPickerIcon:(props)=> <CalendarMonthOutlined {...props} fontSize="small"/>,
                switchViewIcon: (props)=> <KeyboardArrowDown {...props} fontSize="small" />,
                leftArrowIcon:(props)=><KeyboardArrowLeft {...props} fontSize="small" />,
                rightArrowIcon:(props)=><KeyboardArrowRight {...props} fontSize="small" />,
            }
        }
    }
}

export const CalendarMonthOutlined = (props)=><SvgIcon {...props}>
    <path
        d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zm0-12H5V6h14zM9 14H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2zm-8 4H7v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z"
    />
</SvgIcon>

export const KeyboardArrowDown = (props)=><SvgIcon {...props}>
    <path
        d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"
    />
</SvgIcon>

export const KeyboardArrowLeft = (props)=><SvgIcon {...props}>
    <path
        d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z"
    />
</SvgIcon>

export const KeyboardArrowRight = (props)=><SvgIcon {...props}>
    <path
        d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"
    />
</SvgIcon>