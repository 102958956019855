export default function DesktopDatePicker(){
    return {
        defaultProps: {
            slotProps: {
                desktopPaper: {
                    sx: {
                        borderRadius: 2,
                        boxShadow: 4
                    }
                }
            }
        }
    }
}