export default function DialogActions(){
    return {
        styleOverrides: {
            root: {
                padding: 24
            },
            spacing: {
                gap: 12
            }
        }
    }
}