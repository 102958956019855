import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";


export default function ToggleTicketsList({collapseList,setCollapseList,bottom='unset'}){
    return <Tooltip title={`${collapseList?'Open':'Close'} ticket list`}>
        <IconButton
            sx={{
                position:'absolute',
                bottom,
                left:0,
                borderTopLeftRadius:0,
                borderBottomLeftRadius:0,
                backgroundColor:'primary.main',
                color:'primary.contrastText',
                '&:hover': {
                    backgroundColor:'primary.dark',
                }
            }}
            onClick={()=>{
                setCollapseList(prev=>!prev);
            }}
            size={'small'}
        >
            {collapseList
                ?<ArrowForwardIosRoundedIcon fontSize={'small'}/>
                :<ArrowBackIosNewRoundedIcon fontSize={'small'}/>
            }
        </IconButton>
    </Tooltip>
}