import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function SearchInput({filter,setFilter}){
    return <TextField
        fullWidth
        sx={(theme)=>({
            "& .MuiOutlinedInput-notchedOutline":{
                borderColor:theme.palette.mode==='dark'
                    ?theme.palette.grey[500]
                    :theme.palette.grey[200],
            }
        })}
        InputProps={{
            sx:(theme)=>({
                backgroundColor:theme.palette.mode==='dark'
                    ?theme.palette.grey[700]
                    :theme.palette.grey[50],
                borderRadius:2,
                "& .MuiInputBase-input":{
                    pr:0
                }
            }),
            startAdornment:<InputAdornment  position={'start'}>
                <SearchIcon fontSize={'small'}/>
            </InputAdornment>,
            endAdornment:<InputAdornment position={'end'}>
                <Tooltip title={'Clear search'} arrow>
                    <IconButton
                        size={"small"}
                        onClick={()=>{
                            setFilter(prevState => ({...prevState,search:''}))
                        }}
                    >
                        <CloseRoundedIcon fontSize={"small"} />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        }}
        value={filter.search}
        onChange={(e)=>{
            setFilter(prev=>({...prev,search:e.target.value}))
        }}
        placeholder={'Search...'}
    />
}