export default function TablePagination(theme){
    return {
        styleOverrides: {
            select: {
                ":focus": {
                    borderRadius: 8
                }
            },
            actions: {
                color: theme.palette.grey[600]
            },
            menuItem: {
                marginInline: 8,
                borderRadius: 8,
                justifyContent: "center"
            }
        }
    }
}