export default function Dialog(){
    return {
        styleOverrides: {
            paper: {
                borderRadius: 12
            },
            paperFullScreen: {
                borderRadius: 0
            }
        }
    }
}