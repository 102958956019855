import React, {useEffect, useState} from "react";
import {
    Card,
    Box,
    Divider,
    Avatar,
    Typography,
    IconButton,
    Tooltip,
    Collapse,
    Grid,
    Chip,
    LinearProgress
} from "@mui/material";
import StatusChip from "../StatusChip";
import PriorityIcon from "../PriorityIcon";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Details from "./Details";
import MyMessage from "./MyMessage";
import AskMessage from "./AskMessage";
import MessageEditor from "./RichTextEditor/MessageEditor";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {useOutletContext, useParams} from "react-router-dom";
import ToggleTicketsList from "./ToggleTicketsList";
import {useSelector} from "react-redux";
import {getTicketsMessages} from "../messengerApi";
import {getDiffTimeString} from "../../../Utils";
import sound from "../../../assets/notification.mp3";
import {ALLOW_SOUND} from "../../../store/ticketsSlice";

const soundAlert = new Audio(sound);

function getNextMessage(messages){
    const messagesArr = Object.values(messages);

    for(let i=0;i<messagesArr.length;i++){
        let newRow = {
            ...messagesArr[i],
            ts_formatted:getDiffTimeString(messagesArr[i].ts*1000),
            date:new Date(messagesArr[i].ts*1000)
                .toLocaleString([],{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: "2-digit",
                    minute: "2-digit"
                }),
            isNext:false,
        };
        let prev = messagesArr[i-1];
        if( prev && prev.is_admin === newRow.is_admin && prev.user === newRow.user ){
            newRow.isNext=true;
        }
        messagesArr[i] = newRow;
    }
    return messagesArr.reverse()
}

const ticketChartMoreButton = localStorage.getItem('ticketChartMoreButton')!=='false';
export default function TicketChart({}){
    const { id } = useParams();
    const tickets = useSelector(store => store.ticketsReducer.tickets);
    const [collapseList,setCollapseList] = useOutletContext();
    const [openMore,setOpenMore] = useState(ticketChartMoreButton);
    const [ticket,setTicket] = useState({});
    const [messages,setMessages] = useState([]);
    const [loadingMessages,setLoadingMessages] = useState(false);
    useEffect(()=>{
        localStorage.setItem('ticketChartMoreButton',openMore);
    },[openMore]);
    useEffect(() => {
        if(id && Array.isArray(tickets)){
            const ticket = tickets.find(el=>el.id==id)||{};
            setTicket(prevState => {
                if(prevState.id==ticket.id){
                    return prevState
                }
                return ticket
            })
        }
    }, [id,tickets]);
    function GetTicketsMessagesWrapper(notify){
       return getTicketsMessages(id)
            .then(response=>{
                if(response.data.data){
                    setMessages((prevState)=>{
                        const newPosts = getNextMessage(response.data.data);
                        if(
                            Notification.permission==='granted'
                            && notify 
                            && prevState.length 
                            && prevState.length<newPosts.length
                        ){
                            localStorage.getItem(ALLOW_SOUND)==='true' && soundAlert.play();
                            new Notification(`New message in #${id}`,{
                                icon:'/logo192.png',
                                body:newPosts[0].text
                            });
                        }
                        return newPosts
                    });
                }
            })
            
    }
    useEffect(() => {
        setLoadingMessages(true);
        GetTicketsMessagesWrapper()
            .finally(e=>{
                setLoadingMessages(false);
            });
        const interval = setInterval(()=>{
            GetTicketsMessagesWrapper(true)
        },10000);
        return ()=>{
            clearInterval(interval);
            setMessages([]);
        }
    }, [id]);
    return <Card >
        <Box p={3} display={'flex'}
             sx={{
                 position:'relative'
             }}
             alignItems={'flex-start'} justifyContent={'flex-start'} gap={1}>
            <Avatar src={ticket.requester_avatar}>{ticket.requester_name&&ticket.requester_name[0]}</Avatar>
            
            <Box flexGrow={1} maxWidth={'100%'}>
                <Typography fontWeight={700} fontSize={16}>
                    Ticket #{ticket.id||id}
                </Typography>
                <Grid container alignItems={'center'} >
                    <Grid item container alignItems={'center'} xs={6} gap={1}>
                        <Typography display={'flex'} alignItems={'center'} gap={.5} fontSize={12}>
                            <b>Priority:</b> <PriorityIcon priority={ticket.priority}/> {ticket.priority}
                        </Typography>
                    </Grid>
                    <Grid item container alignItems={'center'} xs={6} >
                        <Typography fontSize={12}>
                          <b>Due date:</b> {ticket.due_date}
                        </Typography>
                        <Box flexGrow={1} />
                        <Tooltip title={openMore?'Show less':'Show more'}>
                            <IconButton size={'small'} onClick={()=>setOpenMore(prev=>!prev)}>
                                {openMore
                                    ?<ExpandLessRoundedIcon fontSize={"small"} />
                                    :<ExpandMoreRoundedIcon fontSize={"small"} />
                                }
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Collapse in={openMore}>
                    <Details ticket={ticket} />
                </Collapse>
            </Box>
            <ToggleTicketsList
                collapseList={collapseList}
                setCollapseList={setCollapseList}
                bottom={4}
            />
        </Box>
        <Divider/>
        <Box sx={{position:'relative',width:'100%'}}>
            {loadingMessages&&<LinearProgress sx={{position:'absolute',width:'100%'}}/>}
        </Box>
        <Box sx={{
            overflowY:'auto',
            height:`calc(100vh - 412px)`,
            display:'flex',
            flexDirection:'column-reverse',
            overflowAnchor: 'none',
            '& .slack-mdk a':{
                color:'orange',
                '&:hover':{
                    textDecoration:'underline'
                }
            },
            '& .slack-mdk code,& pre':{
                border: '1px solid',
                padding: '2px 4px',
                borderRadius: '4px',
                backgroundColor:'primary.dark',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
            },
            '& .slack-mdk pre>code':{
                all:'revert'
            },
            '& .slack-mdk ol,& ul':{
                paddingLeft: 2.5
            },
            '& .slack-mdk blockquote':{
                borderLeft:'3px solid'
            },
            '& .slack-mdk blockquote>p':{
                paddingLeft:1.5,
            }
        }}>
            {messages.map((m,i)=>m.is_admin
                ?<MyMessage key={i} messageBody={m} />
                :<AskMessage key={i} messageBody={m} />)
            }
            {!messages.length && !loadingMessages &&<Chip sx={{
                m:'auto'
            }} label={"No messages!"} />}
        </Box>
        <Box p={1}>
            <MessageEditor 
                id={id}
                GetTicketsMessagesWrapper={GetTicketsMessagesWrapper}
                setLoading={setLoadingMessages}
                setMessages={setMessages}
            />
        </Box>
    </Card>
}