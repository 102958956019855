import React from "react";
import {IconButton, styled} from "@mui/material";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function AppDrawerHeader({open,setOpen}){
    return <DrawerHeader>
        <IconButton onClick={()=>{
            setOpen(state=>!state)
        }}>
            <MenuOpenRoundedIcon 
                sx={(theme)=>({
                    transform:`rotate(${open?180:0}deg)`,
                    transition:theme.transitions.create('transform')
                })}
            />
        </IconButton>
    </DrawerHeader>
}