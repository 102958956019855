import React, {useCallback, useEffect, useState} from "react";
import {Box,Grid} from "@mui/material";
import Header from "./Header";
import SelectList from "./SelectList";

const initialArray = [];

export default function TransitionListView(
    {
        Title="",
        onChange=(()=>{}),
        initialAllElements=initialArray,
        initialSelected=initialArray,
        loading,
        printWithId=false,
        sortByName=false,
        selectedButtonText='Disapprove All selected',
        selectedHeaderText='Approved selected',
        unselectedButtonText='Approved All selected',
        unselectedHeaderText='Unapproved selected',
        elevation=5
    }
){
    const [selected,setSelected] = useState([]);
    const [unselected,setUnselected] = useState([]);
    const [allElements,setAllElements] = useState([]);
    const [isFiltered,setIsFiltered] = useState(false);
    useEffect(()=>{
        let convertedInitialAllElements=initialArray;
        if(typeof initialAllElements === 'object'&&initialAllElements!==null){
            convertedInitialAllElements = objectToTransitionListData(initialAllElements)
        }else if(Array.isArray(initialAllElements)){
            convertedInitialAllElements = arrayToTransitionListData(initialAllElements)
        }
        
        setSelected(initialSelected);
        setUnselected(convertedInitialAllElements.filter(el=> !initialSelected.some(elSelected => elSelected.id === el.id)) );
        setAllElements(convertedInitialAllElements);
    },[initialAllElements,initialSelected]);
    useEffect(()=>{
        onChange(selected.map(el=>el.id));
    },[selected]);
    function handleSelectAll(){
        setSelected(prevState=>[...(prevState.filter(el=>!el.inFiltered)),...(allElements.filter(el=>el.inFiltered))]);
        setUnselected(prevState=>[...(prevState.filter(el=>!el.inFiltered))])
    }
    function handleSortClick(target){
        const sortComparator = sortByName
            ?(a,b)=>{
                if(!a.name||!b.name){
                    return 0
                }
                return a.name.toString().localeCompare(b.name.toString())
            }
            :(a,b)=>(+a.id - +b.id);
        if(target==='unselected'){
            setUnselected(prevState => [...prevState].sort(sortComparator))
        }
        else {
            setSelected(prevState => [...prevState].sort(sortComparator))
        }
    }

    function handleUnselectAll(){
        setSelected(prevState=>[...(prevState.filter(el=>!el.inFiltered))]);
        setUnselected(prevState=>[...(prevState.filter(el=>!el.inFiltered)),...(allElements.filter(el=>el.inFiltered))])
    }
    const select = useCallback((id)=>{
        setSelected(prevState => [allElements.find(el=>el.id===id),...prevState]);
        setUnselected(prevState => prevState.filter(el=>el.id!==id));
    },[allElements]);

    const unselect = useCallback((id)=>{
        setSelected(prevState => prevState.filter(el=>el.id!==id));
        setUnselected(prevState => [allElements.find(el=>el.id===id),...prevState]);
    },[allElements]);
    function getSearch(search){
        setIsFiltered(search!=='')
    }
    const [animateItems,setAnimateItems] = useState([]);
    return (
        <Box sx={{
            p:3
        }}>
            <Header
                Title={Title}
                setSelected={setSelected}
                setUnselected={setUnselected}
                setAllElements={setAllElements}
                getSearch={getSearch}
                printWithId={printWithId}
            />
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={6}
            >
                <SelectList
                    itemList={selected}
                    buttonText={selectedButtonText}
                    onButtonClick={handleUnselectAll}
                    onItemClick={unselect}
                    headerText={`${selectedHeaderText} ${(selected.filter(el=>el.inFiltered)).length} from  ${selected.length}`}
                    isFiltered={isFiltered}
                    onSortClick={handleSortClick.bind(this,'selected')}
                    slide={'left'}
                    loading={loading}
                    printWithId={printWithId}
                    animateItems={animateItems}
                    setAnimateItems={setAnimateItems}
                    elevation={elevation}
                />
                <SelectList
                    itemList={unselected}
                    buttonText={unselectedButtonText}
                    onButtonClick={handleSelectAll}
                    onItemClick={select}
                    slide={'right'}
                    isFiltered={isFiltered}
                    onSortClick={handleSortClick.bind(this,'unselected')}
                    headerText={`${unselectedHeaderText} ${(unselected.filter(el=>el.inFiltered)).length} from ${unselected.length}`}
                    loading={loading}
                    printWithId={printWithId}
                    sortByName={sortByName}
                    animateItems={animateItems}
                    setAnimateItems={setAnimateItems}
                    elevation={elevation}
                />
            </Grid>
        </Box>
    )
}

function createTransitionItem(id,name){
    return {id,name,inFiltered:true}
}

function arrayToTransitionListData(array,nameField='name',idField='id'){
    return array.map(([key,el])=>({id:el[idField],name:el[nameField],inFiltered:true}))
}

function objectToTransitionListData(object){
    return Object.entries(object).map(([id,name])=>({id,name,inFiltered:true}))
}
