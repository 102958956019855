import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import SpaceSelectorHeader from "./SpaceSelectorHeader";
import CreateDialog from "../KanbanBoard/CreateDialog";
import TicketChart from "./TicketChart";
import TicketsList from "./TicketsList";
import {useDispatch, useSelector} from "react-redux";
import {getTickets} from "../KanbanBoard/ticketsApi";
import {setTickets} from "../../store/ticketsSlice";
import {Outlet, useParams} from "react-router-dom";

export default function Messenger(){
    const { id } = useParams();
    const [exclude,setExclude] = useState([]);
    const [openCreate,setOpenCreate] = useState(false);
    const [collapseList,setCollapseList] = useState(false);
    const tickets = useSelector(store => store.ticketsReducer.tickets);
    const dispatch = useDispatch();
    useEffect(() => {
       getTickets()
           .then(response=>{
               if(Array.isArray(response.data?.data)){
                   dispatch(setTickets(response.data?.data));
               }
           });
    }, []);
    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SpaceSelectorHeader
                    exclude={exclude}
                    setExclude={setExclude}
                />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid
                    item
                    xs={12} 
                    md={4}
                    sx={theme=>({
                        overflow:'hidden',
                        transition:theme.transitions.create('all',
                            {duration:theme.transitions.duration.standard}
                        ),
                    ...collapseList&&{
                        flexBasis:'0 !important',
                        paddingLeft: '0 !important',
                    }})}
                > 
                    <TicketsList excludeTeams={exclude} tickets={tickets} setOpenCreate={setOpenCreate} />
                </Grid>
                <Grid 
                    item
                    xs={12}
                    sx={theme=>({
                        transition:theme.transitions.create('all',
                            {duration:theme.transitions.duration.standard}
                        ),
                    })}  
                    md={collapseList?12:8}>
                    <Outlet context={[collapseList,setCollapseList]}/>
                </Grid>
            </Grid>
        </Grid>
        <CreateDialog
            open={openCreate}
            onClose={()=>setOpenCreate(false)}
        />
    </Box>
}