import {createSlice} from "@reduxjs/toolkit";

const storedModeDark = localStorage.getItem('modeDark')
export const themeSlice = createSlice({
    name:'theme',
    initialState:{
        modeDark:storedModeDark==='true'||storedModeDark==='false'?JSON.parse(storedModeDark):true,
        forceDark:false,
    },
    reducers:{
        toggleMode: (state)=>{
            state.modeDark =!state.modeDark;
        },
        setMode: (state,{payload})=>{
            state.modeDark = Boolean(payload);
        },
        setForceDark:(state,{payload})=>{
            state.forceDark =Boolean(payload);
        }
    }
});

export const {toggleMode,setMode,setForceDark} = themeSlice.actions;

export default themeSlice.reducer