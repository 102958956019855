import React from "react";
import {Box, Grid, IconButton, Typography} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

export default function PriorityTitle(
    {
        collapse,
        setCollapse,
        name,
        CircleRoundedIconProps,
        counter=0
    }
){
    return <Grid item container alignItems={'center'} gap={2} xs={12}>
        <IconButton onClick={()=>{
            setCollapse(st=>!st)
        }}>
            <KeyboardArrowDownRoundedIcon sx={(theme)=>({
                transform:`rotate(${collapse?0:-90}deg)`
            })}/>
        </IconButton>
        <Box sx={{display:'flex',cursor:'pointer',gap:2,flexGrow:1}} onClick={()=>setCollapse(st=>!st)}>
            <CircleRoundedIcon {...CircleRoundedIconProps} />
            <Typography>
                {name} priority
                {' '}
                <Typography 
                    component={'span'} 
                    color={"disabled"}
                    variant={'body2'}
                >
                    ({counter} tickets)
                </Typography>
            </Typography>
        </Box>
    </Grid>
}