export default function Fab(){
    return {
        styleOverrides: {
            extended: ({ownerState: {color: t}})=>({
                gap: 4,
                lineHeight: 1,
                textTransform: "none",
                ...(t === "success" || t === "warning") && {
                    color: "white"
                }
            }),
            circular: ({ownerState: {color: t}})=>({
                lineHeight: 1,
                ...(t === "success" || t === "warning") && {
                    color: "white"
                }
            }),
            sizeSmall: ({ownerState: {variant: t}})=>({
                fontSize: 14,
                svg: {
                    fontSize: 20
                },
                ...t === "extended" && {
                    paddingInline: "1rem"
                }
            })
        }
    }
}