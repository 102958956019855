import {Grid, Tooltip} from "@mui/material";
import CustomEditor from "../CustomEditor";
import React from "react";
import ToolbarButton from "./ToolbarButton";
import {useSlate} from "slate-react";
import {blockTooltips} from "./Tooltips";

export default function BlockButton({format,Icon}){
    const editor = useSlate();
    return <Grid item>
        <Tooltip title={blockTooltips[format]} placement={'top'} arrow>
            <ToolbarButton
                variant={CustomEditor.isBlockActive(editor,format)?'contained':'text'}
                color={CustomEditor.isBlockActive(editor,format)?'primary':'secondary'}
                onMouseDown={(e)=>{
                    e.preventDefault();
                    CustomEditor.toggleBlock(editor,format);
                }}
            >
                <Icon/>
            </ToolbarButton>
        </Tooltip>
    </Grid>
}