export default function OutlinedInput(theme){
    const isDark = theme.palette.mode==='dark'
    return {
        styleOverrides: {
            input: {
                color: theme.palette.text.primary
            },
            adornedEnd: {
                color: theme.palette.grey[400]
            },
            adornedStart: {
                color: theme.palette.grey[400]
            },
            inputSizeSmall: {
                padding: "12px 14px"
            },
            sizeSmall: {
                fontSize: 14,
                fontWeight: 400
            },
            notchedOutline: {
                borderRadius: 8,
                borderColor: isDark ? theme.palette.grey[700] : theme.palette.grey[200]
            }
        }
    }
}