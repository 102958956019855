import {useSlate} from "slate-react";
import {Grid, Tooltip,Menu,useTheme} from "@mui/material";
import ToolbarButton from "./ToolbarButton";
import React, {useState} from "react";
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import EmojiEmotionsRoundedIcon from '@mui/icons-material/EmojiEmotionsRounded';
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {Transforms} from "slate";

export default function EmojiButton(){
    const editor = useSlate();
    const [hover,setHover]= useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    return <Grid item>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            sx={{
                background:'transparent',
                '& ul':{
                p:0
            }}}
        >
            <Picker  theme={theme.palette.mode} data={data} onEmojiSelect={(emoji)=>{
                Transforms.insertText(editor, emoji.native);
                handleClose();
            }} />
        </Menu>
        <Tooltip title={'Emoji'} placement={'top'} arrow>
            <ToolbarButton
                onMouseEnter={()=>{
                    setHover(true)
                }}
                onMouseLeave={()=>{
                    setHover(false)
                }}
                variant={'text'}
                color={"primary"}
                onMouseDown={(e)=>{
                    e.preventDefault();
                    handleOpen(e)
                }}
            >
                {hover?<EmojiEmotionsRoundedIcon sx={{transform:'rotate(20deg)'}} color={'warning'}/>:<SentimentSatisfiedAltRoundedIcon/>}
            </ToolbarButton>
        </Tooltip>
    </Grid>
}