import {createTheme} from "@mui/material/styles";
import {shadows} from "./shadows";
import {error, info, primary, secondary, success, warning,grey} from "./colors";

import components from "./components";

const theme = createTheme({
    palette:{
        mode:'light',
        primary,
        secondary,
        success,
        warning,
        error,
        info,
        grey,
        background:{
            paper:'#fff'
        }
    },
    typography: { fontFamily: "'Inter', sans-serif" },
    shadows:shadows('light'),
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    }
});


const lightTheme = createTheme({
    ...theme,
    components:components(theme)

});

export default lightTheme;