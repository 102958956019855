export default function Avatar(theme){
    const {grey, primary} = theme.palette;
    const isDark = theme.palette.mode === 'dark';
    return {
        variants: [{
            props: {
                variant: "bordered"
            },
            style: {
                padding: 3,
                backgroundOrigin: "border-box",
                border: "double 1px transparent",
                backgroundClip: "padding-box, border-box",
                backgroundImage: `linear-gradient(white, white), conic-gradient(from 30deg, ${primary.main} 180deg, ${grey[200]} 180deg)`,
                ...isDark && {
                    backgroundImage: `linear-gradient(${grey[800]}, ${grey[800]}), conic-gradient(from 30deg, ${primary.main} 180deg, ${grey[800]} 180deg)`
                }
            }
        }]
    }}