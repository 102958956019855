import React, { useEffect, useState,useRef} from "react";
import {createEditor} from "slate";
import {Slate,Editable,withReact,} from "slate-react";
import { Grid, Button,} from "@mui/material";
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import StrikethroughSRoundedIcon from '@mui/icons-material/StrikethroughSRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import CodeOffRoundedIcon from '@mui/icons-material/CodeOffRounded';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MarkButton from "./Buttons/MarkButton";
import FormattingDivider from "./Buttons/FormattingDivider";
import BlockButton from "./Buttons/BlockButton";
import useRenderElements from "./RenderElements";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import useRenderLeaf from "./RenderLeaf";
import isHotkey,{isKeyHotkey} from 'is-hotkey';
import CustomEditor,{withInlines} from "./CustomEditor";
import { serialize } from 'remark-slate';
import slackify from "slackify-markdown";
import {sendMessage} from "../../messengerApi";
import {useSnackbar} from "notistack";
import EmojiButton from "./Buttons/EmojiButton";
import LinkButton from "./Buttons/LinkButton";
import {Range,Transforms} from "slate";


const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+shift+x': 'strikeThrough',
    'mod+shift+c': 'code',
}

const HOTKEYS_BLOCKS ={
    'mod+option+shift+c': 'code_block',
    'mod+shift+9': 'block_quote',
    'mod+shift+8': 'ul_list',
    'mod+shift+7': 'ol_list',
}

const initialValue = [
    {
        type: 'paragraph',
        children:[{text:''}]
    }
];

export default function MessageEditor({id,GetTicketsMessagesWrapper,setLoading,setMessages}){
    const [editor] = useState(()=>  withInlines(withReact(createEditor())));
    const messageBody = useRef(initialValue); 
    const renderElement = useRenderElements();
    const renderLeaf = useRenderLeaf();
    const [focus,setFocus] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    function clearMessage(){
        const point = { path: [0, 0], offset: 0 }
        editor.selection = { anchor: point, focus: point };
        editor.history = { redos: [], undos: [] };
        editor.children = [{
            type: "paragraph",
            children: [{ text: "" }]
        }];
        messageBody.current = initialValue;
    }
    useEffect(() => {
        clearMessage()
    }, [id]);
    function handleSendMsg(){
        const slackMessage = slackify(messageBody.current.map(v=>{
            let node = v;
            // add \n to each row for correct compatibility
            if(node.type==='code_block'){
                node= {
                    ...v,
                    children: v.children.map((code_line,index)=>({
                        ...code_line,
                        children:v.children.length-1===index
                            ?code_line.children
                            :[...code_line.children,{text:'\n'}]
                    }))
                }
            }else if(node.type==='block_quote'){
               return v.children
                   .map(el=>`> ${serialize({...el,type:el.type==='code_line'?'text':el.type})
                       ?.replaceAll('+ ','&#43; ')}`)
                   .join('\n')+'\n\n';
            }
            return serialize(node)?.replaceAll('+ ','&#43; ')
        })
            .join(''))
            .replaceAll('&amp;','&')
            .replaceAll('&amp;','&')
            .replaceAll('&quot;','\"')
            .replaceAll('&#39;','\'')
            .replaceAll('<br>','')
            .replace(/```\n([\s\S]+?)\n```/g, '```$1```');

        if(slackMessage==='\n'){
            return;
        }
        setLoading(true);
        clearMessage();
        setMessages(prevState=>{
            const firstMsg = prevState[1];
            return [{
                avatar: "",
                isNext: firstMsg?.is_admin,
                is_admin: true,
                team: "",
                text: slackMessage,
                ts: new Date().getTime()/1000,
                date:"Just now",
                ts_formatted: "Just now",
                user: "Support bot Test",
            },...prevState]
        })
        sendMessage(id,{
            "message": slackMessage
        })
            .catch(e=>{
                console.log(e);
                enqueueSnackbar('Message not sent!',{variant:'error'});
            })
            .finally(()=>{
                GetTicketsMessagesWrapper()
                    .finally(()=>{
                        setLoading(false)
                    })
            });
    }
    return (<>
    <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={value=>{
            messageBody.current =value;
        }}
    >
        <Grid container p={1} sx={({
            borderRadius:3,
            border:'1px solid',
            borderColor:'divider',
            outline:focus?'1px solid':'none',
        })} gap={.5}>
            <Grid item container gap={.5}>
                <MarkButton  
                    Icon={FormatBoldRoundedIcon}
                    format={"bold"}
                />
                <MarkButton
                    Icon={FormatItalicRoundedIcon}
                    format={"italic"}
                />
                <MarkButton
                    Icon={StrikethroughSRoundedIcon}
                    format={"strikeThrough"}
                />
                <FormattingDivider/>
                <LinkButton/>
                <FormattingDivider/>
                
                <BlockButton
                    Icon={FormatListBulletedRoundedIcon}
                    format={'ul_list'}
                />
                <BlockButton
                    Icon={FormatListNumberedRoundedIcon}
                    format={'ol_list'}
                />
                <FormattingDivider/>
                <BlockButton
                    Icon={FormatQuoteIcon}
                    format={'block_quote'}
                />
                <FormattingDivider/>
                <MarkButton
                    Icon={CodeOffRoundedIcon}
                    format={"code"}
                />
                <BlockButton
                    Icon={IntegrationInstructionsIcon}
                    format={'code_block'}
                />
            </Grid>
            <Grid item xs={12}>
                <Editable
                    editor={editor}
                    style={{outline:'none'}}
                    placeholder={'Message...'}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onFocus={e=>{
                        setFocus(true)
                    }}
                    onBlur={e=>{
                        setFocus(false)
                    }}
                    onKeyDown={event => {
                        if(isHotkey('enter',event)) {
                            handleSendMsg();
                            event.preventDefault();
                            return;
                        }
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                CustomEditor.toggleMark(editor, mark)
                            }
                        }
                        for (const hotkeyBlock in HOTKEYS_BLOCKS) {
                            if (isHotkey(hotkeyBlock, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS_BLOCKS[hotkeyBlock]
                                CustomEditor.toggleBlock(editor, mark)
                            }
                        }

                        if (isHotkey('mod+shift+u', event)) {
                            event.preventDefault()
                          CustomEditor.toggleLink(editor);
                        }
                        const { selection } = editor;
                        if (selection && Range.isCollapsed(selection)) {
                            const { nativeEvent } = event
                            if (isKeyHotkey('left', nativeEvent)) {
                                event.preventDefault()
                                Transforms.move(editor, { unit: 'offset', reverse: true })
                                return
                            }
                            
                            if (isKeyHotkey('right', nativeEvent)) {
                                event.preventDefault()
                                Transforms.move(editor, { unit: 'offset' })
                                return
                            }
                        }
                    }}
                />
            </Grid>
            <Grid item container justifyContent={'space-between'} xs={12}>
                <Grid item container flex={1}>
                    <EmojiButton/>
                </Grid>
                
                <Button
                    size={'small'} 
                    variant={"contained"}
                    endIcon={<SendRoundedIcon/>}
                    onClick={handleSendMsg}
                >
                    Send
                </Button>
            </Grid>
        </Grid>
    </Slate>
    </>)     
}