import React, {useMemo} from "react";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import {Button, Link, Box} from "@mui/material";
import {orange} from "@mui/material/colors";

const imageTypes = ['png','jpg','jpeg','webp','gif','svg','bmp','tiff','apng','avif','ico','cur'];

const videoTypes = ['mp4','mov','avi','wmv','flv','mkv'];

function FilesView({file,dark}){
    const { link,name,type} = file;
    const Icon = useMemo(()=>{
        switch (true){
            case imageTypes.includes(type):
                return ImageIcon;
            case videoTypes.includes(type):
                return VideoFileIcon
            default:
                return InsertDriveFileIcon
        }
    },[type])
    return <Link target="_blank" href={link} sx={{maxWidth:'100%',}}>
        <Button variant={'text'} color={'warning'} sx={(theme)=>({
            p:.5,
            maxWidth:'100%',
            textOverflow:'ellipsis',
            overflow:'hidden',
            justifyContent:'flex-start',
            gap:.5,
            ...(theme.palette.mode==='light'&&dark&&{
                color:orange[800]
            })
        })}>
            <Icon/>
            {name}
        </Button>
    </Link>
}

export default function FileWrapper({files=[],dark}){
    return <Box sx={{
        mx:-.75,
        display:'flex',
        justifyContent:'flex-start',
        flexWrap:'wrap',
        gap:.5,
        pt:.5
    }}>
        {
            files.map((file,key)=><FilesView key={key} file={file} dark={dark}/> )
        }
    </Box>
}