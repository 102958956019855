import React from "react";
import {Card, CardContent} from "@mui/material";
import {Droppable} from "@hello-pangea/dnd";
import BoardTicketCard, {TicketCard} from "./BoardTicketCard";
import {blue} from "@mui/material/colors";
import { WindowScroller, List } from 'react-virtualized';

const getRowRender =
    (tickets,handleEditDialog) =>
        ({ index, style }) => {
    
            const ticket = tickets[index];
            if(!ticket){
                return null;
            }
            return <div key={ticket.id} style={style}>
                <BoardTicketCard
                    ticket={ticket}
                    handleEditDialog={handleEditDialog}
                    index={index}
                />
            </div>
        };

export default function BoardColumn({children, droppableId, childArray, handleEditDialog,priority}){
    const ref = React.useRef(null);
    return <Droppable 
        type={priority}
        droppableId={droppableId}
        mode="virtual"
        renderClone={(
            provided,
            snapshot,
            rubric,
        ) => (<div
            style={{
                maxWidth:185,
                height:213.5
            }}
        >
            <TicketCard
                ticket={childArray[rubric.source.index]}
                handleEditDialog={handleEditDialog}
                provided={provided}
                snapshot={snapshot}
            />
        </div>)}
    >
        {(provided,snapshot)=>(
            <Card
                sx={({
                    minHeight:270,
                    overflowY:'auto',
                    height:'100%',
                    ...(snapshot.isDraggingOver?{
                        outline:'2px solid',
                        outlineColor:blue[800]
                    }:{}),
                })}
                ref={provided.innerRef}
                {...provided.droppableProps}
            >
                <CardContent >
                    <div ref={ref}/>
                    <WindowScroller>
                        {({ height, isScrolling }) => {
                            const calcScrollTop = Math.min(Math.abs(Math.min(+ref.current?.getBoundingClientRect().top,0)),childArray.length*229.5);
                            return (
                                <List
                                    autoHeight
                                    rowCount={snapshot.isUsingPlaceholder?childArray.length+1: childArray.length}
                                    height={height}
                                    isScrolling={isScrolling}
                                    scrollTop={calcScrollTop}
                                    rowHeight={229.5}
                                    width={184}
                                    rowRenderer={getRowRender(childArray,handleEditDialog)}
                                />
                            )
                        }}
                    </WindowScroller>
                    {children}
                </CardContent>
            </Card>
        )}
    </Droppable>
}