export default function PaginationItem(theme){
    const {primary, grey} = theme.palette;
    const isDark = theme.palette.mode==="dark";
    return {
        styleOverrides: {
            rounded: {
                borderRadius: 8
            },
            icon: ({ownerState: {color: r}})=>({
                ...r === "primary" && {
                    color: primary.main
                },
                ...r === "secondary" && {
                    color: grey[700]
                },
                ...r === "standard" && {
                    color: grey[600]
                }
            }),
            ellipsis: {
                border: 0
            },
            text: ({ownerState: {color: r}})=>({
                color: grey[400],
                border: `1px solid ${grey[isDark ? 700 : 200]}`,
                "&.Mui-selected": {
                    border: 0,
                    ...r === "standard" && {
                        color: grey[500],
                        backgroundColor: grey[200],
                        ":hover": {
                            backgroundColor: grey[200]
                        }
                    }
                }
            }),
            textSecondary: {
                "&.Mui-selected": {
                    color: "white",
                    backgroundColor: grey[700],
                    ":hover": {
                        backgroundColor: grey[700]
                    }
                }
            },
            outlined: ({ownerState: {color: r}})=>({
                color: grey[400],
                border: `1px solid ${grey[isDark ? 700 : 200]}`,
                ...r === "standard" && {
                    "&.Mui-selected": {
                        color: grey[700],
                        borderColor: grey[200],
                        backgroundColor: grey[100],
                        ":hover": {
                            backgroundColor: grey[100]
                        }
                    }
                }
            }),
            outlinedSecondary: {
                "&.Mui-selected": {
                    color: grey[700],
                    borderColor: grey[300],
                    backgroundColor: grey[200],
                    ":hover": {
                        backgroundColor: grey[300]
                    }
                }
            }
        }
    }}