export default function AvatarGroup(theme){
    const isDark = theme.palette.mode;
    return {
        styleOverrides: {
            avatar: {
                width: 36,
                height: 36,
                padding: 0,
                fontSize: 12,
                border: "2px solid white",
                ...isDark && {
                    border: `1px solid ${theme.palette.grey[600]}`
                }
            },
            root: {
                justifyContent: "flex-end"
            }
        }
    }
}