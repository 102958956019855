export default function Input(theme){
    return {
        styleOverrides: {
            root: {
                ":before": {
                    borderColor: theme.palette.grey[400]
                }
            }
        }
    }
}