export default function Chip(theme){
    const {common, grey} = theme.palette;
    const isDark = theme.palette.mode === 'dark';
    return {
        defaultProps: {
            color: "primary"
        },
        styleOverrides: {
            root: {
                fontWeight: 500,
                borderRadius: 16
            },
            filled: ({ownerState: {color: r}})=>({
                color: common.white,
                ...r === "default" && {
                    backgroundColor: grey[500]
                }
            }),
            outlined: ({ownerState: {color: r}})=>({
                ...r === "default" && {
                    color: grey[400]
                }
            }),
            filledSecondary: {
                color: isDark ? grey[50] : grey[700],
                backgroundColor: isDark ? grey[700] : grey[100]
            },
            outlinedSecondary: {
                color: grey[700],
                borderColor: grey[700]
            },
            avatar: {
                ":has(img[src])": {
                    backgroundColor: "transparent"
                }
            },
            deleteIcon: ({ownerState: {variant: r, color: o, size: i}})=>({
                opacity: .8,
                fontSize: 18,
                ":hover": {
                    opacity: 1,
                    color: "inherit"
                },
                ...r === "outlined" && {
                    color: "inherit"
                },
                ...r === "filled" && {
                    color: o === "secondary" ? "inherit" : "white"
                },
                ...i === "small" && {
                    fontSize: 16
                }
            }),
            clickable: ({ownerState: {color: r}})=>({
                ...r === "default" && {
                    ":hover": {
                        backgroundColor: grey[700]
                    }
                }
            }),
            sizeSmall: {
                fontSize: 13
            }
        }
    }
}