import {SvgIcon} from "@mui/material";

export default function Alert(theme){
    const {primary, success, error, warning, common, grey} = theme.palette;
    const isDark = theme.palette.mode==='dark';
    const standard = e=>({
        color: e.main,
        backgroundColor: e[50]
    })
        , outlined = e=>({
        color: e.main,
        borderColor: e.main,
        backgroundColor: e[50]
    })
        , action = (e,t)=>({
        "& .btn-group button": {
            ":first-of-type": {
                border: `1px solid ${t}`,
                marginRight: "1rem"
            },
            ":last-of-type": {
                backgroundColor: t,
                color: e
            }
        }
    });
    return {
        defaultProps: {
            iconMapping: {
                info: <Info/>,
                error:<Error/>,
                success: <Success/>,
                warning: <Warning/>
            }
        },
        styleOverrides: {
            root: {
                borderRadius: 16,
                fontSize: 12,
                fontWeight: 600,
                alignItems: "center"
            },
            standardError: standard(error),
            standardSuccess: standard(success),
            standardWarning: standard(warning),
            standardInfo: {
                ...standard(primary),
                "& .MuiAlert-icon": {
                    color: primary.main
                }
            },
            outlinedError: outlined(error),
            outlinedSuccess: outlined(warning),
            outlinedWarning: outlined(warning),
            outlinedInfo: {
                ...outlined(primary),
                "& .MuiAlert-icon": {
                    color: primary.main
                },
                ...isDark && {
                    backgroundColor: grey[700]
                }
            },
            filledWarning: {
                color: common.white
            },
            filledSuccess: {
                color: common.white,
                backgroundColor: success[600]
            },
            filledInfo: {
                color: common.white,
                backgroundColor: primary.main
            },
            action: ({ownerState: {severity: a, variant: l}})=>({
                ":has( > .btn-group)": {
                    padding: 0,
                    "& button": {
                        borderRadius: 10,
                        padding: ".5rem 1rem",
                        fontWeight: 600
                    }
                },
                ...a === "info" && {
                    ...l === "filled" && action(primary.main, common.white),
                    ...l === "outlined" && action(common.white, primary.main),
                    ...l === "standard" && action(common.white, primary.main)
                },
                ...a === "error" && {
                    ...l === "filled" && action(error.main, common.white),
                    ...l === "outlined" && action(common.white, error.main),
                    ...l === "standard" && action(common.white, error.main)
                },
                ...a === "success" && {
                    ...l === "filled" && action(success.main, common.white),
                    ...l === "outlined" && action(common.white, success.main),
                    ...l === "standard" && action(common.white, success.main)
                },
                ...a === "warning" && {
                    ...l === "filled" && action(warning.main, common.white),
                    ...l === "outlined" && action(common.white, warning.main),
                    ...l === "standard" && action(common.white, warning.main)
                }
            })
        }
    }
}

const Info = (props)=><SvgIcon {...props}>
    <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m1 15h-2v-6h2zm0-8h-2V7h2z"
    />
</SvgIcon>;

const Error = (props)=><SvgIcon {...props}>
    <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m1 15h-2v-2h2zm0-4h-2V7h2z"
    />
</SvgIcon>;

const Success = (props)=><SvgIcon {...props}>
    <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8z"
    />
</SvgIcon>;

const Warning = (props)=><SvgIcon {...props}>
    <path
        d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z"
    />
</SvgIcon>    