import {createSlice} from "@reduxjs/toolkit";

export const optionsSlice = createSlice(
    {
        name: 'options',
        initialState:{
            teams:[]
        },
        reducers:{
            setTeams:(state,{payload})=>{
                state.teams = payload
            },
        }
    }
);

export const {setTeams} = optionsSlice.actions;

export default optionsSlice.reducer;