import {styled} from "@mui/material";
import React from "react";

const CodeBlock = styled('pre')(({theme})=>({
    border: '1px solid',
    padding: '2px 4px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.dark,
    whiteSpace: 'pre-wrap',     
    wordWrap: 'break-word',

}));

const CodeElement = ({attributes,children}) =>(
    <CodeBlock {...attributes}>
        <code>
            {children}
        </code>
    </CodeBlock>
)

export default CodeElement;