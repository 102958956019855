import CssBaseLine from "./CssBaseLine";
import Radio from "./Radio";
import Fab from "./Fab";
import Chip from "./Chip";
import Alert from "./Alert";
import Badge from "./Badge";
import Switch from "./Switch";
import Rating from "./Rating";
import DataGrid from "./DataGrid";
import Backdrop from "./Backdrop";
import Breadcrumbs from "./Breadcrumbs";
import Autocomplete from "./Autocomplete";
import Avatar from "./Avatar";
import AvatarGroup from "./AvatarGroup";
import Button from "./Button";
import IconButton from "./IconButton";
import ButtonBase from "./ButtonBase";
import ButtonGroup from "./ButtonGroup";
import LoadingButton from "./LoadingButton";
import Accordion from "./Accordion";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";
import Pagination from "./Pagination";
import PaginationItem from "./PaginationItem";
import TablePagination from "./TablePagination";
import Dialog from "./Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import ListItemText from "./ListItemText";
import ListItemIcon from "./ListItemIcon";
import Tab from "./Tab";
import Tabs from "./Tabs";
import TabList from "./TabList";
import TabPanel from "./TabPanel";
import DatePicker from "./DatePicker";
import DesktopDatePicker from "./DesktopDatePicker";
import TimePicker from "./TimePicker";
import DateTimePicker from "./DateTimePicker";
import DesktopDateTimePicker from "./DesktopDateTimePicker";
import Input from "./Input";
import InputLabel from "./InputLabel";
import FilledInput from "./FilledInput";
import OutlinedInput from "./OutlinedInput";
import Checkbox from "./Checkbox";
import {grey} from "../colors";

export default function components(theme){
    const isDark = theme.palette.mode==='dark';
    return {
        MuiCssBaseline: CssBaseLine(theme),
        MuiRadio:Radio(),
        MuiFab:Fab(),
        MuiChip:Chip(theme),
        MuiAlert:Alert(theme),
        MuiBadge:Badge(theme),
        MuiSwitch:Switch(theme),
        MuiRating:Rating(theme),
        MuiDataGrid:DataGrid(theme),
        MuiBackdrop:Backdrop(theme),
        MuiBreadcrumbs:Breadcrumbs(theme),
        MuiAutocomplete:Autocomplete(),
        MuiAvatar: Avatar(theme),
        MuiAvatarGroup:AvatarGroup(theme),
        MuiButton:Button(theme),
        MuiIconButton:IconButton(theme),
        MuiButtonBase:ButtonBase(),
        MuiButtonGroup:ButtonGroup(theme),
        MuiLoadingButton:LoadingButton(theme),
        MuiAccordion:Accordion(theme),
        MuiAccordionSummary:AccordionSummary(theme),
        MuiAccordionDetails:AccordionDetails(theme),
        MuiPagination:Pagination(),
        MuiPaginationItem:PaginationItem(theme),
        MuiTablePagination:TablePagination(theme),
        MuiDialog:Dialog(),
        MuiDialogTitle:DialogTitle(),
        MuiDialogContent:DialogContent(),
        MuiDialogActions:DialogActions(),
        MuiMenu:Menu(),
        MuiMenuItem:MenuItem(),
        MuiListItemText:ListItemText(),
        MuiListItemIcon:ListItemIcon(theme),
        MuiTab:Tab(theme),
        MuiTabs:Tabs(theme),
        MuiTabList:TabList(),
        MuiTabPanel:TabPanel(),
        MuiDatePicker:DatePicker(),
        MuiDesktopDatePicker:DesktopDatePicker(),
        MuiTimePicker:TimePicker(),
        MuiDateTimePicker:DateTimePicker(),
        MuiDesktopDateTimePicker: DesktopDateTimePicker(),
        MuiInput:Input(theme),
        MuiInputLabel: InputLabel(theme),
        MuiFilledInput:FilledInput(theme),
        MuiOutlinedInput:OutlinedInput(theme),
        MuiCheckbox: Checkbox(theme),
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: grey[400]
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: grey[300]
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                valueLabel: {
                    borderRadius: 8
                },
                markLabel: {
                    color: grey[500],
                    fontSize: 12,
                    fontWeight: 500
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                    boxShadow: theme.shadows[2]
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                size: "small"
            },
            styleOverrides:{
                root:{
                    '& input:-webkit-autofill': {
                        borderRadius:8,
                        WebkitBoxShadow: `0 0 0 100px ${theme.palette.primary.dark} inset`
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "& .secondary": {
                        opacity: .4
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundImage: "none",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
                    backgroundColor:isDark?grey[800]:"#fff",
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                    color: grey[isDark ? 200 : 500]
                }
            },
            defaultProps: {
                padding: "none"
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 6,
                    flexGrow: 1,
                    borderRadius: 16,
                    backgroundColor: isDark ? grey[700] : grey[200]
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    textDecoration: "none"
                }
            }
        },

        MuiTimelineDot: {
            defaultProps: {
                variant: "filled",
                color: "grey"
            },
            styleOverrides: {
                filledGrey: {
                    backgroundColor: grey[isDark ? 100 : 300],
                    "& .MuiSvgIcon-root": {
                        color: grey[600]
                    }
                }
            }
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    backgroundColor: grey[isDark ? 700 : 100]
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 8,
                    backgroundColor: isDark ? grey[700] : grey[900]
                },
                arrow: {
                    color: isDark ? grey[700] : grey[900]
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                root: {
                    ".MuiTreeItem-content": {
                        borderRadius: 4
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none"
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundColor: grey[800]
                }
            }
        }
    }
}