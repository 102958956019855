import React from "react";
import {Box, CircularProgress} from "@mui/material";

export default function ListOverlay(){
    return (
        <Box
            sx={({
                position: 'absolute',
                zIndex: 20,
                top: 0,
                left: 0,
                right :0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.1)',
            })}
        >
            <CircularProgress style={{margin:'auto'}} />
        </Box>
    );
}