import {alpha} from "@mui/material/styles";

export default function Button(theme){
    const {error, primary, text, success, warning, grey}  = theme.palette;
    const isDark = theme.palette.mode==='dark';
    return {
        defaultProps: {
            variant: "contained",
            color: "primary"
        },
        styleOverrides:{
            root:{
                fontWeight: 500,
                borderRadius: 10,
                color: "inherit",
                boxShadow: "none",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textTransform: "none",
                textOverflow: "ellipsis",
                disabled:'none',
                "&.Mui-disabled": {
                    color: grey[400]
                }
            },
            contained: {
                color: "white",
                ":hover": {
                    boxShadow: "none"
                },
                "&.Mui-disabled": {
                    backgroundColor: grey[isDark? 600 : 200]
                }
            },
            containedError: {
                ":hover": {
                    backgroundColor: error[600]
                }
            },
            containedPrimary: {
                ":hover": {
                    backgroundColor: primary[600]
                }
            },
            containedSuccess: {
                ":hover": {
                    backgroundColor: success[700]
                }
            },
            containedWarning: {
                ":hover": {
                    backgroundColor: warning[500]
                }
            },
            containedSecondary: {
                transition: "none",
                color: text.primary,
                ":hover": {
                    backgroundColor: grey[200]
                },
                ...isDark && {
                    backgroundColor: grey[700],
                    ":hover": {
                        backgroundColor: grey[600]
                    }
                }
            },
            containedInherit: {
                backgroundColor: text.primary,
                ":hover": {
                    backgroundColor: alpha(text.primary, .9)
                },
                ...isDark && {
                    color: "black"
                }
            },
            outlinedError: {
                color: error.main
            },
            outlinedPrimary: {
                color: primary.main
            },
            outlinedSuccess: {
                color: success.main
            },
            outlinedWarning: {
                color: warning.main
            },
            outlinedSecondary: {
                transition: "none",
                borderColor: grey[200],
                ...isDark && {
                    borderColor: grey[700]
                }
            },
            textPrimary: {
                color: primary.main
            },
            textSecondary: {
                color: grey[600]
            },
            textSuccess: {
                color: success.main
            },
            textWarning: {
                color: warning.main
            },
            textError: {
                color: error.main
            },
            sizeSmall: {
                padding: "0.25rem .5rem",
                height: 30
            },
            sizeMedium: {
                padding: "6px 14px"
            },
            sizeLarge: {
                padding: "8px 16px",
                height: 48
            }
        }
    }
}