import {grey, primary} from "../colors";

export default function CssBaseLine(theme){
    const isDark = theme.palette.mode==="dark";
    return {
        styleOverrides: {
            "*": {
                margin: 0,
                padding: 0,
                boxSizing: "border-box",
                scrollBehavior: "smooth",
                colorScheme:isDark?'dark':'light',
            },
            html: {
                width: "100%",
                height: "100%",
                WebkitOverflowScrolling: "touch",
                MozOsxFontSmoothing: "grayscale"
            },
            body: {
                width: "100%",
                height: "100%",
                backgroundColor: isDark?grey[900]:"#fdfdff"
            },
            a: {
                color: primary.main,
                textDecoration: "none"
            },
            input: {
                "&[type=number]": {
                    MozAppearance: "textfield",
                    "&::-webkit-outer-spin-button": {
                        margin: 0,
                        WebkitAppearance: "none"
                    },
                    "&::-webkit-inner-spin-button": {
                        margin: 0,
                        WebkitAppearance: "none"
                    }
                }
            },
            "#root": {
                width: "100%",
                height: "100%",
                "& .apexcharts-xaxistooltip": {
                    display: "none"
                },
                "& .apexcharts-tooltip": {
                    border: "none",
                    borderRadius: 8,
                    boxShadow: theme.shadows[2]
                }
            },
            "#nprogress .bar": {
                zIndex: "9999 !important",
                backgroundColor: primary.main
            },
            "#nprogress .peg": {
                boxShadow: "none"
            },
            ".h-full": {
                height: "100%"
            },
            ".p-3": {
                padding: theme.spacing(3)
            },
            ".pt-2": {
                paddingTop: theme.spacing(2)
            },
            ".pb-0": {
                paddingBottom: theme.spacing(0)
            },
            ".pb-4": {
                paddingBottom: theme.spacing(4)
            },
            ".py-10": {
                paddingBlock: theme.spacing(10)
            },
            ".py-12": {
                paddingBlock: theme.spacing(12)
            }
        }
    }
}