import axios from "axios";
import {BASE_URL} from "../../router/API";
import {authProvider} from "../../authProvider";

export const getTicketsMessages = (id)=>{
    return axios({
        method:'GET',
        url:`tickets/${id}/messages`,
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
    })
};

export const getTeamsList = ()=>{
    return axios({
        method:'GET',
        url:`teams`,
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
    })
}

export const sendMessage = (id,body)=>{
    return axios({
        method: 'POST',
        url:`tickets/${id}/message`,
        baseURL:BASE_URL,
        headers:{
            'X-Requested-With':'XMLHttpRequest',
            'Authorization' : `Bearer ${authProvider.token}`
        },
        responseType: 'json',
        data:body
    })
}