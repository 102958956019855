import {alpha} from "@mui/material/styles";

export default function FilledInput(theme){
    const isDark = theme.palette.mode==='dark';
    return {
        defaultProps: {
            disableUnderline: !0
        },
        styleOverrides: {
            root: ({ownerState: {color: t, error: n}})=>({
                borderRadius: 8,
                border: "1px solid transparent",
                backgroundColor: n ? theme.palette.error[50] : theme.palette.grey[100],
                transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                ":hover": {
                    backgroundColor: n ? theme.palette.error[50] : theme.palette.grey[100]
                },
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.grey[isDark ? 400 : 200]
                },
                "&.Mui-focused": {
                    backgroundColor: n ? theme.palette.error[50] : theme.palette.grey[100],
                    ...t === "primary" && {
                        border: `1px solid ${n ? theme.palette.error.main : theme.palette.primary.main}`,
                        boxShadow: `${n ? theme.palette.error.main : theme.palette.primary.main} 0 0 0 1px`
                    },
                    ...isDark && {
                        backgroundColor: n ? alpha(theme.palette.error[900], .2) : theme.palette.grey[800]
                    }
                },
                ...isDark && {
                    backgroundColor: n ? alpha(theme.palette.error[900], .2) : theme.palette.grey[800],
                    ":hover": {
                        backgroundColor: n ? alpha(theme.palette.error[900], .2) : theme.palette.grey[800]
                    }
                }
            }),
            sizeSmall: {
                fontSize: 14,
                fontWeight: 400
            }
        }
    }
}